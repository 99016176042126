<template>
  <div>
    <Btn label="Добавить целевое действие" type="success" icon="plus" :action="handleOpen" :fullwidth="false" />
    <Modal :open="open" :title="title" :handleClose="handleClose" size="medium">
      <AddPayableActionForm @clear-form="open=false" />
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/ui/modal.vue';
import Btn from '@/components/ui/btn.vue';
import AddPayableActionForm from '@/components/forms/AddPayableActionForm.vue';

export default {
  name: 'AddPayableActionModal',
  components: {
    AddPayableActionForm,
    Modal,
    Btn,
  },
  data() {
    return {
      open: false,
      title:  "Добавить целевое действие"
    };
  },
  methods: {
    handleOpen() {
      this.open = true;
    },
    handleClose() {
      this.open = false;
    },
  }
}
</script>