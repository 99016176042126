<template>
  <a href="#!" @click.prevent="handleClick" :class="'c-modal__subheader-tab'+(active ? ' is-active' : '')">{{ title }}</a>
</template>

<script>
export default {
  name: 'TabHeading',
  methods: {
    handleClick() {
      this.onClick(this.target);
    }
  },
  props: {
    title: {
      type: String,
      required: true
    },
    target: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    },
    onClick: {
      type: Function,
      default: () => {}
    }
  }
}
</script>