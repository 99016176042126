import Vue from 'vue'
import Vuex from 'vuex'

import app from './app.js';
import user from './user.js';
import users from './users.js';
import employee from './employee.js';
import parts from './parts.js';
import laptop from './laptop.js';
import laptops from './laptops.js';
import views from './views.js';
import payableAction from './payable-action.js';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    user,
    users,
    employee,
    laptop,
    laptops,
    views,
    parts,
    payableAction,
  }
})
