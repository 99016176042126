export const PasswordGeneratorMixin = {
    data() {
        return {
            passwordShown: false,
        }
    },
    mounted() {
        let eye = document.querySelector('.c-field__password-toggler');
        eye.addEventListener('click', () => {
            this.passwordShown = !this.passwordShown;
        });
    },
    methods: {
        handlePasswordGeneration() {
            this.password.value = this.generatePassword();
            let eye = document.querySelector('.c-field__password-toggler');
            if (this.passwordShown === true) {
                eye.click();
            }
        },
        generatePassword() {
            return Math.random().toString(36).substr(2, 8) + Math.random().toString(36).substr(2, 4);
        }
    }
}