<template>
  <div class="row u-mb-large">
    <div class="col-sm-12">
      <div class="c-table-responsive@desktop">
        <table class="c-table c-table--highlight">
          <caption class="c-table__title">
            Все представления
            <span v-if="action && isAdmin()" class="c-table__title-action">
                <component :is="action"></component>
              </span>
          </caption>
          <thead class="c-table__head c-table__head--slim">
          <tr class="c-table__row">
            <th class="c-table__cell c-table__cell--head">#</th>
            <th class="c-table__cell c-table__cell--head u-text-center">Иконка</th>
            <th class="c-table__cell c-table__cell--head">Заголовок</th>
            <th class="c-table__cell c-table__cell--head">Поля</th>
            <th class="c-table__cell c-table__cell--head">Статус</th>
            <th class="c-table__cell c-table__cell--head">
              <span class="u-hidden-visually">Действия</span>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(item, key) in items"
              :key="key"
              class="c-table__row"
          >
            <td class="c-table__cell">
              {{ key + 1 }}
            </td>
            <td class="c-table__cell u-text-center c-table__icon-cell">
              <i :class="['fa', item.menu_icon]"></i>
            </td>
            <td class="c-table__cell">
              {{ item.title }}
            </td>
            <td class="c-table__cell c-table__fields-cell">
                  <span
                      class="c-badge c-badge--info"
                      v-for="(field, key) in item.fields"
                      :key="key"
                  >
                    {{ field.label }}
                  </span>
            </td>
            <td class="c-table__cell">
              {{ getRoles(item.roles) }}
            </td>
            <td class="c-table__cell u-text-right">
              <DropdownMenu :links="getLinks(item.id)" label="Действия"/>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import DropdownMenu from '@/components/ui/dropdown-menu.vue';
import AddViewModal from '@/components/ui/Modals/AddViewModal.vue';
import routes from '@/constants/server.js';

import axios from '@/js/http-client.js';
import {mapActions, mapMutations} from 'vuex';

import {isAdmin, getRole} from '@/js/helpers.js';

export default {
  name: 'ViewsTable',
  components: {
    DropdownMenu,
    AddViewModal
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    action: {
      type: [Object, Boolean],
      default: false
    }
  },
  methods: {
    isAdmin,
    getRoles(roles) {
      if (roles) {
        let r = [];
        roles.split(',').map((role) => {
          r.push(getRole(role));
        });
        return r.join(', ');
      } else {
        return 'Все';
      }
    },
    ...mapActions('views', [
      'fetchData',
      'setViewObj'
    ]),
    ...mapActions('app', [
      'getLaptopsNav',
    ]),
    ...mapMutations('views', [
       'openModal',
       'closeModal'
    ]),
    handleDelete(id) {
      let result = confirm("Вы уверены, что хотите удалить представление? Это действие необратимо.");

      if (result) {
        axios.delete(routes.view.delete + id)
            .then((res) => {
              console.log(res);
              this.fetchData();
              this.getLaptopsNav();
            })
            .catch((error) => {
              console.log(error);
            });
      }
    },
    getLinks(id) {
      return [
        {
          label: "Редактировать",
          href: "/#/",
          action: () => {
            let viewForEditing = this.items.filter(function (el) {
              return el.id === id;
            });
            viewForEditing = viewForEditing[0];
            console.log('=======');
            console.info(viewForEditing);
            this.setViewObj(viewForEditing);
            this.openModal();
          },
        },
        {
          label: "Удалить",
          href: "/#/views/",
          action: () => {
            this.handleDelete(id)
          },
        },
      ];
    },
  }
}
</script>

<style lang="css">
.c-badge {
  font-size: 10px;
  font-weight: 500;
  margin-right: 5px;
  margin-bottom: 5px;
}

.c-table__fields-cell {
  max-width: 300px;
  display: flex;
  flex-wrap: wrap;
}

.c-table__icon-cell {
  font-size: 20px;
  opacity: .5;
}
</style>