<template>
  <div>
    <div class="c-modal__body c-modal__body--user">
      <ModalPreloader v-if="loading" />
      <div class="row mb15">
        <div class="col">
          <h4 class="u-mb-medium">
            Новый сотрудник
          </h4>
          <!-- START: AVATAR -->
          <div class="c-field mb15">
            <AvatarUploadable
              :url="avatar.value"
              :request="handleAvatarUpload"
              :title="avatarName"
              :subtitle="avatarRole"
              size="xlarge"
            />
          </div>
          <!-- END: AVATAR -->
          <!-- START: NAME & SURNAME -->
          <div class="row">
            <div class="col-sm-6">
              <div class="mb15">
                <TextField
                  :label="name.label"
                  v-model="name.value"
                  :placeholder="name.placeholder"
                  :legend="name.legend"
                />
              </div>
            </div>
            <div class="col-sm-6">
              <div class="mb15">
                <TextField
                  :label="surname.label"
                  v-model="surname.value"
                  :placeholder="surname.placeholder"
                  :legend="surname.legend"
                />
              </div>
            </div>
          </div>
          <!-- END: NAME & SURNAME -->
          <!-- START: LOGIN, PASSWORD etc.-->
          <div class="row">
            <div class="col-sm-6">
              
              <div class="mb15">
                <TextField
                  :label="login.label"
                  v-model="login.value"
                  :placeholder="login.placeholder"
                  :legend="login.legend"
                />
              </div>
            </div>
            <div class="col-sm-6">
              <div class="mb15">
                <TextField
                  :label="role.label"
                  v-model="role.value"
                  :placeholder="role.placeholder"
                  :legend="role.legend"
                  :options="role.options"
                  v-bind:hiddenValue="role.hiddenValue"
                  v-on:update:hiddenValue="role.hiddenValue = $event"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="mb15 o-line">
                <TextField
                    :label="password.label"
                    v-model="password.value"
                    :placeholder="password.placeholder"
                    :legend="password.legend"
                    type="password"
                  />
                <span
                  @click="handlePasswordGeneration"
                  aria-label="Сгенерировать пароль"
                  class="c-password-generator c-tooltip c-tooltip--top"
                >
                  <img src="/img/dice.svg" alt="Сгенерировать пароль">
                </span>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="mb15">
                <TextField
                  :label="email.label"
                  v-model="email.value"
                  :placeholder="email.placeholder"
                  :legend="email.legend"
                />
              </div>
            </div>
          </div>
          <!-- END: LOGIN, PASSWORD etc.-->
        </div>

      </div>
    </div>
    <div class="c-modal__footer u-justify-end">
      <Alert
        v-if="success.is || error.is"
        :type="alert.type"
        :text="alert.text"
        :icon="alert.icon"
      />
      <Btn v-else :action="handleSubmit" icon="floppy-o" :label="'Сохранить сотрудника'" :fullwidth="false" type="success" />
    </div>
  </div>
</template>

<script>
import TextField from '@/components/ui/Fields/TextField.vue';
import ModalPreloader from '@/components/ui/modal-preloader.vue';
import AvatarUploadable from '@/components/ui/avatar-uploadable.vue';
import Alert from '@/components/ui/alert.vue';
import Btn from '@/components/ui/btn.vue';
import roles from '@/constants/roles.js';

//import fields from '@/constants/fields.js';
import routes from '@/constants/server.js';
import {PasswordGeneratorMixin} from '../mixins/PasswordGeneratorMixin';

import { mapActions } from 'vuex';

import axios from '@/js/http-client.js';

export default {
  name: 'AddUserForm',
  components: {
    TextField,
    AvatarUploadable,
    ModalPreloader,
    Alert,
    Btn,
  },
  mixins: [PasswordGeneratorMixin],
  methods: {
    handleAvatarUpload(form, config) {
      axios.post(routes.user.avatar, form, config)
        .then(response => {
          this.avatar.value = response.data.url;
        })
        .catch(error => {
          console.log(error);
        });
    },
    handleSubmit() {
      this.loading = true;
      axios.post(routes.user.save, this.formData)
        .then(response => {
          console.log(response);
          this.success.is = true;
          this.loading = false;
          // this.getLaptopsNav();
          setTimeout(this.clear.bind(this), 2000);
        })
        .catch(error => {
          console.log(error);
          this.error.is = true;
          this.loading = false;
          setTimeout(() => this.error.is = false, 2000)
        });
    },
    clear() {
      this.success.is = false;
      this.error.is = false;
      this.$emit('clear-form');
      setTimeout(this.fetchData, 1000);
    },
    ...mapActions('app', [
      'getLaptopsNav',
    ]),
    ...mapActions('views', [
      'fetchData',
    ]),
  },
  computed: {
    avatarName() {
      let r = [];
      if (this.name.value.length > 0) {
        r.push(this.name.value);
      }
      if (this.surname.value.length > 0) {
        r.push(this.surname.value);
      }

      if (r.length > 0) {
        return r.join(' ');
      } else {
        return false;
      }
    },
    avatarRole() {
      return this.role.value.length > 0 ? this.role.value : false;
    },
    formData() {
      return {
        login: this.login.value,
        email: this.email.value,
        name: this.name.value,
        surname: this.surname.value,
        password: this.password.value,
        avatar: this.avatar.value,
        role: this.role.hiddenValue,
      }
    },
    alert() {
      let alert;
      if (this.success.is) {
        alert = {
          type: 'success',
          icon: 'check',
          text: this.success.message,
        };
      }
      if (this.error.is) {
        alert = {
          type: 'danger',
          icon: 'times',
          text: this.error.message,
        };
      }
      return alert;
    }
  },
  data() {
    return {
      loading: false,
      success: {
        message: 'Сотрудник успешно сохранен',
        is: false,
      },
      error: {
        message: 'Произошла ошибка при обращении к серверу',
        is: false,
      },
      login: {
        value: '',
        placeholder: '',
        legend: 'Только латинские буквы',
        label: 'Логин',
      },
      email: {
        value: '',
        placeholder: '',
        legend: 'Адрес электронной почты',
        label: 'Email',
      },
      name: {
        value: '',
        placeholder: '',
        label: 'Имя',
        legend: 'Введите имя сотрудника',
      },
      surname: {
        value: '',
        placeholder: '',
        label: 'Фамилия',
        legend: 'Введите фамилию сотрудника',
      },
      password: {
        value: '',
        placeholder: '',
        label: 'Пароль',
        legend: 'Только латинские буквы и цифры',
      },
      role: {
        value: '',
        label: 'Роль',
        hiddenValue: '',
        placeholder: 'Менеджер',
        legend: 'Выберите роль сотрудника',
        options: roles
      },
      avatar: {
        value: 'http://www.noteapp.landingheroes.ru/wp-content/uploads/2020/10/stub.jpg', 
      }
    }
  },
}
</script>

<style lang="css">
  .c-modal__body {
    min-height: 500px;
  }
  .c-modal__footer .c-alert {
    margin-bottom: 0;
  }
  .c-password-generator {
    width: 26px;
    margin-left: 10px;
    position: relative;
    top: -3px;
    opacity: .7;
    cursor: pointer;
    transition: .2s linear;
  }

  .c-password-generator:hover {
    opacity: 1;
  }

  .c-modal__body--user {
    height: 600px;
  }
  
</style>