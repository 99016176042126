<template>
  <div>
    <div class="c-modal__body c-modal__body--maximized">
      <ModalPreloader v-if="loading"/>
      <div class="row mb15">
        <div class="col-sm-6">
          <h4 class="u-mb-medium">
            Общая информация
          </h4>
          <div class="mb15">
            <TextField
                :label="title.label"
                v-model="title.value"
                :placeholder="title.placeholder"
                :legend="title.legend"
            />
          </div>
          <div class="mb15">
            <IconField ref="iconField"/>
          </div>
          <div class="mb15">
            <TextField
                :label="roles.label"
                v-model="roles.value"
                :placeholder="roles.placeholder"
                :legend="roles.legend"
                :options="roles.options"
                v-bind:hiddenValue="roles.hiddenValue"
                v-on:update:hiddenValue="roles.hiddenValue = $event"
                :multiple="true"
            />
          </div>
          <h4 class="u-mb-medium">Автофильтры</h4>
          <div class="row">
            <AutofiltersField ref="filtersField" :rows="filters"/>
          </div>

        </div>
        <div class="col-sm-6">
          <h4 class="u-mb-medium">Поля</h4>
          <RepeaterField :rows="fields" addLabel="Добавить поле"/>

        </div>
      </div>
    </div>
    <div class="c-modal__footer u-justify-end">
      <Alert
          v-if="success.is || error.is"
          :type="alert.type"
          :text="alert.text"
          :icon="alert.icon"
      />
      <Btn v-else :action="handleSubmit" icon="floppy-o" :label="'Сохранить представление'" :fullwidth="false"
           type="success"/>
    </div>
  </div>
</template>

<script>
import TextField from '@/components/ui/Fields/TextField.vue';
import IconField from '@/components/ui/Fields/icon-field.vue';
import RepeaterField from '@/components/ui/Fields/repeater-field.vue';
import AutofiltersField from '@/components/ui/Fields/autofilters-field.vue';
import ModalPreloader from '@/components/ui/modal-preloader.vue';
import Alert from '@/components/ui/alert.vue';
import Btn from '@/components/ui/btn.vue';

import fields from '@/constants/fields.js';
import routes from '@/constants/server.js';

import {mapActions, mapGetters} from 'vuex';

import axios from '@/js/http-client.js';
import roles from '@/constants/roles.js';
import {getRole} from '@/js/helpers.js';

export default {
  name: 'AddViewForm',
  components: {
    TextField,
    IconField,
    RepeaterField,
    AutofiltersField,
    ModalPreloader,
    Alert,
    Btn,
  },
  methods: {
    ...mapActions('app', [
      'getLaptopsNav',
    ]),
    ...mapActions('views', [
      'fetchData',
      'setViewObj'
    ]),
    getRoles(roles) {
      if (roles) {
        let r = [];
        roles.split(',').map((role) => {
          r.push(getRole(role));
        });
        return r.join(',');
      }
    },
    addView() {
      axios.post(routes.view.add, this.formData)
          .then(response => {
            console.log(response);
            this.setViewObj(false);
            this.success.is = true;
            this.loading = false;
            this.getLaptopsNav();
            setTimeout(this.clear.bind(this), 2000);
          })
          .catch(error => {
            console.log(error);
            this.setViewObj(false);
            this.error.is = true;
            this.loading = false;
            setTimeout(this.clear.bind(this), 2000);
          });
    },
    editView() {
      axios.put(routes.view.update + this.viewObj.id, this.formData)
          .then(response => {
            console.log(response);
            this.setViewObj(false);
            this.success.is = true;
            this.loading = false;
            this.getLaptopsNav();
            setTimeout(this.clear.bind(this), 2000);
          })
          .catch(error => {
            console.log(error);
            this.setViewObj(false);
            this.error.is = true;
            this.loading = false;
            setTimeout(this.clear.bind(this), 2000);
          });
    },
    handleSubmit() {
      this.loading = true;
      console.log('ID')
      console.log(this.viewObj.id);
      if (this.viewObj.id === undefined || this.viewObj.id === false) {
        this.addView();
      } else {
        this.editView();
      }
    },
    clear() {
      this.setViewObj(false);
      this.success.is = false;
      this.error.is = false;
      this.$emit('clear-form');
      setTimeout(this.fetchData, 300);
    },
  },
  watch: {
    // preload form with data for editing
    viewObj(viewForEdit) {
      if (viewForEdit !== false && viewForEdit !== 'undefined') {
        this.title['value'] = viewForEdit.title;

        this.$refs.iconField.value = viewForEdit.menu_icon;

        if (viewForEdit.roles !== '') {
          this.roles.value = this.getRoles(viewForEdit.roles);
          this.roles.hiddenValue = viewForEdit.roles;
        }

        let filtersView = viewForEdit.filters;
        if (filtersView[0].value !== '') {
          this.filters = [];
          filtersView.forEach(function (filter) {
            let temp = [{
              value: filter.field.label,
              hiddenValue: filter.field.value,
              label: 'Поле',
              legend: 'Поле фильтрации',
              placeholder: 'Статус',
              options: {
                status: 'Статус',
                brand: 'Бренд',
              }
            },
              {
                value: filter.value,
                label: 'Значение',
                legend: 'Значение фильтра',
                placeholder: '',
                endpoint: false,
              }];
            this.filters.push(temp);
          }, this);
        }

        let fieldsView = viewForEdit.fields;
        if (fieldsView[0] !== '') {
          this.fields = [];
          fieldsView.forEach(function (field) {
            let temp = [
              {
                value: field.label,
                label: 'Поле',
                legend: 'Выберите поле для отображения',
                options: fields,
                hiddenValue: fields.indexOf(field.label)
              }
              ,
            ];
            this.fields.push(temp);
          }, this);
        }
      }
    }
  },
  computed: {
    ...mapGetters('views', [
      'viewObj'
    ]),
    formData() {
      let filteredFields = [];
      this.fields.map(field => {
        filteredFields.push(field[0].value);
      });

      let filteredFilters = [];
      this.$refs.filtersField.dynamicRows.map(filter => {
        filteredFilters.push({
          field: filter[0].hiddenValue,
          label: filter[0].value,
          value: filter[1].value,
        });
      });

      return {
        title: this.title.value,
        fields: filteredFields,
        icon: this.$refs.iconField.value,
        filters: filteredFilters,
        roles: this.roles.hiddenValue,
      }
    },
    alert() {
      let alert;
      if (this.success.is) {
        alert = {
          type: 'success',
          icon: 'check',
          text: this.success.message,
        };
      }
      if (this.error.is) {
        alert = {
          type: 'danger',
          icon: 'times',
          text: this.error.message,
        };
      }
      return alert;
    }
  },
  data() {
    return {
      loading: false,
      success: {
        message: 'Представление успешно сохранено',
        is: false,
      },
      error: {
        message: 'Произошла ошибка при обращении к серверу',
        is: false,
      },
      title: {
        value: '',
        placeholder: 'Продажи',
        legend: 'Наименование представления',
        label: 'Наименование',
      },
      roles: {
        value: '',
        hiddenValue: '',
        placeholder: 'Роли, которым будет доступно представление',
        legend: 'Оставьте поле пустым, чтобы представление было доступно всем',
        label: 'Видимость',
        options: roles,
      },
      fields: [
        [
          {
            value: '',
            label: 'Поле',
            legend: 'Выберите поле для отображения',
            options: fields,
          }
        ],
      ],
      filters: [
        [
          {
            value: '',
            hiddenValue: '',
            label: 'Поле',
            legend: 'Поле фильтрации',
            placeholder: 'Статус',
            options: {
              status: 'Статус',
              brand: 'Бренд',
            }
          },
          {
            value: '',
            label: 'Значение',
            legend: 'Значение фильтра',
            placeholder: '',
            endpoint: false,
          }
        ],
      ],
    }
  },
}
</script>

<style lang="css">
.c-modal__body {
  min-height: 500px;
}

.c-modal__footer .c-alert {
  margin-bottom: 0;
}
</style>