<template>
  <div>
    <h4 class="u-mb-medium">Наименование и модель</h4>
    <div class="row mb15">
      <div class="col-sm-6 col-md-4">
        <TextField
          :label="title.label"
          v-model="title.value"
          :placeholder="title.placeholder"
          :legend="title.legend"
        />
      </div>
      <div class="col-sm-6 col-md-4">
        <TextField
          :label="year.label"
          v-model="year.value"
          :placeholder="year.placeholder"
          :legend="year.legend"
          :options="year.options"
        />
      </div>
      <div class="col-sm-6 col-md-4">
        <TextField
          :label="status.label"
          v-model="status.value"
          :placeholder="status.placeholder"
          :legend="status.legend"
          :endpoint="status.endpoint"
          required
        />
      </div>
    </div>
    <!-- START: ROW -->
    <div class="row mb15">
      <div class="col-sm-6 col-md-4 u-mb-small">
        <TextField
          :label="brand.label"
          v-model="brand.value"
          :placeholder="brand.placeholder"
          :legend="brand.legend"
          :endpoint="brand.endpoint"
        />
      </div>
      <div class="col-sm-6 col-md-4 u-mb-small">
        <TextField
          :label="model.label"
          v-model="model.value"
          :placeholder="model.placeholder"
          :legend="model.legend"
        />
      </div>
<!--      <div class="col-sm-6 col-md-4 u-mb-small">-->
<!--        <TextField-->
<!--          :label="sku.label"-->
<!--          v-model="sku.value"-->
<!--          :placeholder="sku.placeholder"-->
<!--          :legend="sku.legend"-->
<!--        />-->
<!--      </div>-->
    </div>
    <!-- END: ROW -->
    <h4 class="u-mb-medium">Информация о покупке</h4>
    <!-- START: ROW -->
    <div class="row mb15">
      <div class="col-sm-6 col-md-4 u-mb-small">
        <TextField
          :label="buyChannel.label"
          v-model="buyChannel.value"
          :placeholder="buyChannel.placeholder"
          :legend="buyChannel.legend"
          :endpoint="buyChannel.endpoint"
        />
      </div>
      <div class="col-sm-6 col-md-4 u-mb-small">
        <TextField
          :label="buyer.label"
          v-model="buyer.value"
          :placeholder="buyer.placeholder"
          :legend="buyer.legend"
          :endpoint="buyer.endpoint"
        />
      </div>
      <div class="col-sm-6 col-md-4 u-mb-small">
        <TextField
          :label="buyPrice.label"
          v-model="buyPrice.value"
          :placeholder="buyPrice.placeholder"
          :legend="buyPrice.legend"
        />
      </div>
    </div>
    <!-- END: ROW -->
    <h4 class="u-mb-medium">Состояние и комплектация</h4>
    <!-- START: ROW -->
    <div class="row mb15">
      <div class="col-sm-6 col-md-4 u-mb-small">
        <TextField
          :label="state.label"
          v-model="state.value"
          :placeholder="state.placeholder"
          :legend="state.legend"
        />
      </div>
      <div class="col">
        <SwitchField 
          :label="charge.label"
          v-model="charge.value"
          :placeholder="charge.placeholder"
        />
      </div>
      <div class="col">
        <SwitchField 
          :label="box.label"
          v-model="box.value"
          :placeholder="box.placeholder"
        />
      </div>
      <div class="col">
        <SwitchField 
          :label="insurance.label"
          v-model="insurance.value"
          :placeholder="insurance.placeholder"
        />
      </div>
    </div>
    <!-- END: ROW -->
    <h4 class="u-mb-medium">Комментарий</h4>
    <!-- START: ROW -->
    <div class="row mb15">
      <div class="col-sm-6 col-md-6 u-mb-small">
        <TextField
          :label="comment.label"
          v-model="comment.value"
          :placeholder="comment.placeholder"
          :legend="comment.legend"
          :textarea="comment.textarea"
        />
      </div>
    </div>
    <!-- END: ROW -->
  </div>
</template>

<script>
import TextField from '@/components/ui/Fields/TextField.vue';
import SwitchField from '@/components/ui/Fields/switch-field.vue';

import { getYears } from '@/js/helpers.js';
import routes from '@/constants/server.js';


export default {
  name: 'InfoTabForm',
  components: {
    TextField,
    SwitchField,
  },
  data() {
    return {
      title: {
        value: '',
        placeholder: 'Asus для работы и игр',
        legend: 'Краткое описание ноутбука',
        label: 'Описание',
        error: 'Введите описание ноутбука',
      },
      brand: {
        value: '',
        placeholder: 'Asus',
        legend: 'Начните ввод и выберите значение из списка',
        label: 'Бренд',
        endpoint: `${routes.search}brand/`,
        error: 'Выберите бренд ноутбука'
      },
      model: {
        value: '',
        placeholder: 'X302-LA',
        legend: 'Укажите точное название модели',
        label: 'Модель',
        error: 'Укажите точное название модели'
      },
      sku: {
        value: '',
        placeholder: 'ASUS-X302-LA',
        legend: 'Укажите артикул',
        label: 'Артикул',
        error: 'Укажите артикул'
      },
      buyChannel: {
        value: '',
        placeholder: 'Авито',
        legend: 'Начните ввод и выберите значение из списка',
        label: 'Канал покупки',
        endpoint: `${routes.search}buy-channel/`,
        error: 'Укажите канал покупки'
      },
      buyer: {
        value: '',
        placeholder: '',
        legend: 'Укажите того, кто купил ноутбук',
        label: 'Кто купил',
        endpoint: routes.user.search,
        error: 'Укажите того, кто купил ноутбук'
      },
      buyPrice: {
        value: '',
        placeholder: '',
        legend: 'Укажите цену покупки',
        label: 'Цена покупки',
        error: 'Укажите цену покупки'
      },
      state: {
        value: '',
        placeholder: '0',
        legend: 'Оцените состояние ноутбука от 1 до 5',
        label: 'Состояние',
        error: 'Укажите состояние ноутбука от 1 до 5'
      },
      charge: {
        value: false,
        placeholder: 'В комплекте',
        legend: 'Наличие зарядки',
        label: 'Зарядка'
      },
      box: {
        value: false,
        placeholder: 'В комплекте',
        legend: 'Наличие коробки',
        label: 'Коробка'
      },
      insurance: {
        value: false,
        placeholder: 'В комплекте',
        legend: 'Наличие талона',
        label: 'Гарантийный талон'
      },
      status: {
        value: '',
        placeholder: 'В работе',
        legend: 'Укажите статус ноутбука',
        label: 'Статус',
        endpoint: `${routes.search}status/`,
        error: 'Укажите статус ноутбука'
      },
      comment: {
        value: '',
        placeholder: '',
        legend: 'Дополнительный комментарий к ноутбуку',
        label: 'Комментарий',
        textarea: true,
      },
      year: {
        value: '',
        placeholder: '',
        legend: 'Укажите год выпуска ноутбука',
        label: 'Год выпуска',
        options: getYears()
      },
    }
  }
}
</script>