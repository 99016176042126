import axios from '@/js/http-client.js';
import routes from '@/constants/server.js';

import AddPayableActionModal from '@/components/ui/Modals/AddPayableActionModal.vue';

export default {
  namespaced: true,
  state: {
    action: AddPayableActionModal,
    loading: true,
    error: false,
    table: {
      total: 0,
      items: [],
      perPage: 100,
      current: 1,
      pages: 1
    },
  },
  getters: {
    loading: state => state.loading,
    error: state => state.error,
    table: state => state.table,
    action: state => state.action,
  },
  mutations: {
    startLoading: state => state.loading = true,
    stopLoading: state => state.loading = false,
    setTable: (state, table) => state.table = table,
    setError: state => state.error = true,
    unsetError: state => state.error = false,
  },
  actions: {
    fetchData(context) {
      context.commit('startLoading');

      axios.get(routes.payableAction.list)
        .then((response) => {

          context.commit('setTable', response.data);
          context.commit('stopLoading');
        })
        .catch((error) => {
          console.log(error);
          context.commit('setError');
          context.commit('stopLoading');
        });
    }
  }
}