<template>
  <li class="c-sidebar__item">
    <router-link
      :to="path"
      v-slot="{ href, navigate, isActive, }"
    >
      
      <a 
        :href="href"
        :class="['c-sidebar__link', isActive && 'is-active']"
        @click="navigate"
      >
        <i :class="['fa', 'u-mr-xsmall', icon]"></i>
        {{ title }}
        <span
          v-if="badge"
          :class="['c-badge', 'c-badge--xsmall', 'u-ml-xsmall', `c-badge--${badge.color}`]"
        >
        {{ badge.title }}
        </span>
      </a>
      
    </router-link>
  </li>
</template>

<script>
export default {
  name: 'SidebarItem',
  props: {
    path: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: 'fa-laptop'
    },
    badge: {
      type: [Boolean, Object],
      default: false
    }
  }
}
</script>