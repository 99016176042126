import store from '@/store/index.js';
import roles from '@/constants/roles.js';
import actions from '@/constants/actions.js';

/**
 * @description Generates random string for id
 * @return String
 */
export function uid() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

/**
 * @description Converts Objects with observers into simple object
 * @return Object
 */
export function desobserve(object) {
    return JSON.parse(JSON.stringify(object));
}

/**
 * @description isAith callback
 * @return Boolean
 */
function checkAuth() {
    return this.getters['user/token'] !== false && typeof this.getters['user/token'] != 'undefined';
}

/**
 * @description Clears localStorage saved auth objects
 * @return Void
 */
export function clearAuth() {
    localStorage.removeItem('auth-user');
    localStorage.removeItem('auth-jwt');
}

/**
 * @description Returns true if user is authenticated
 * @return Boolean
 */
export function isAuth() {
    return checkAuth.call(store);
}

/**
 * @description Returns token, saved in store
 * @return Boolean, String
 */
export function token() {
    return 'Bearer ' + store.getters['user/token'];
}

/**
 * @description Saves token in local store
 * @return void
 */
export function saveToken(token) {
    localStorage.setItem('auth-jwt', token);
}

/**
 * @description Returns token, saved in slocal tore
 * @return String, Null
 */
export function getToken() {
    localStorage.getItem('auth-jwt');
}

export function getUserId() {
    return store.getters['user/id'];
}

/**
 * @description Saves user in local store
 * @return void
 */
export function saveUser(user) {
    clearAuth();
    localStorage.setItem('auth-user', JSON.stringify(user));
}

/**
 * @description Returns user, saved in local store
 * @return String, Null
 */
export function getUser() {
    const defaultUser = {
        token: false,
        id: "",
        email: "",
        nicename: "",
        firstName: "",
        lastName: "",
        displayName: "",
        avatar: "http://noteapp.landingheroes.ru/wp-content/uploads/2020/10/stub.jpg",
        role: ""
    }
    return JSON.parse(localStorage.getItem('auth-user')) || defaultUser;
}

/**
 * @description Returns true if current user is administrator
 * @return Boolean
 */
export function isAdmin() {
    let user = getUser();
    return user.role == 'administrator';
}

/**
 * @description Returns true if current user is manager
 * @return Boolean
 */
export function isManager() {
    let user = getUser();
    return user.role == 'manager';
}

/**
 * @description Returns true if current user is worker
 * @return Boolean
 */
export function isWorker() {
    let user = getUser();
    return user.role == 'worker';
}

/**
 * @description Returns string in money format
 * @param Number number
 * @return String
 */
export function moneyFormat(number) {
    return `${(+number).toLocaleString()} ₽`;
}

/**
 * @description Returns array of years, starting with start param till now
 * @param Number year
 * @return Array
 */
export function getYears(start = 2010) {
    let current = +(new Date()).getFullYear();
    let years = [];
    for (start; start <= current; start++) {
        years.push(start);
    }
    return years;
}

/**
 * @description Return label of role by it's slug
 * @param String slug
 * @return String
 */
export function getRole(slug) {
    return roles[slug];
}

/**
 * @description Format's date
 * @param String date string in format 'YYYY-MM-DD HH:ii:ss' like '2020-08-02 13:49:09'
 * @param Boolean withTime - return string with time part or not. Default true
 * @return String date string in format 'DD-MM-YYYY HH:ii:ss'
 */
export function formatDate(date, withTime = true) {
    let parts = date.split(' ');
    let newDate = parts[0].split('-').reverse().join('.');
    if (withTime) {
        newDate += ' ' + parts[1];
    }
    return newDate;
}

/**
 * @description Returns action human-readable label by slug
 * @return String
 */
export function getActionLabel(slug) {
    return actions[slug]
}

export function getActionSum(slug, actions) {
    let sum;

    actions.map((action) => {
        if (action.action == slug) {
            sum = action.sum;
        }
    });

    return sum;
}