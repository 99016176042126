<template>
  <div :v-show="active" class="tab-item">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'TabItem',
  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>