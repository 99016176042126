<template>
  <div class="c-card u-p-medium u-mb-medium">
    <h5 class="u-mb-medium">Активность</h5>
    <span class="c-divider u-mv-medium"></span>
    <div class="c-activity-blocks" v-for="(period, key) in historyPeriods" :key="key">
      <div class="c-activity-block" v-if="period.length > 0">
        <p v-if="key == 0" class="u-text-mute u-text-uppercase u-text-small u-mb-xsmall">Сегодня</p>
        <p v-if="key == 1" class="u-text-mute u-text-uppercase u-text-small u-mb-xsmall">Вчера</p>
        <p v-if="key == 2" class="u-text-mute u-text-uppercase u-text-small u-mb-xsmall">Ранее</p>
        <div class="c-feed">
          <div v-for="(entry, index) in period" :key="index" :class="['c-feed__item', getEntryColor(entry.action)]">
            <p><span v-html="prepareEntryTitle(entry.title)"></span><span v-if="entry.comment.length > 0">:</span></p>
            <p class="c-feed__comment" v-if="entry.comment.length > 0"><i class="fa fa-comments-o" style="opacity: .5"></i> {{ entry.comment }}</p>
            <span class="c-feed__meta">{{ entry.date }}</span>
          </div>
        </div>
      </div>
    </div>
    <span class="c-divider u-mv-medium"></span>
    <div v-if="commentFormOpen && !loading" class="c-activity-form">
      <TextField
          label="Текст комментария"
          legend="Введите текст комментария"
          :textarea="true"
          v-model="commentText"
        />
        <div class="u-flex u-mt-medium u-justify-between">
          <Btn :action="handleCloseCommentForm" label="Отмена" type="secondary" :fullwidth="false" />
          <Btn :action="handleSubmitCommentForm" label="Отправить" type="info" :fullwidth="false" icon="paper-plane-o" />
        </div>
         
    </div>
    <div class="u-flex u-mt-medium u-justify-center" v-else-if="loading && !success.is">
      <Preloader size="sm" />
    </div>
    <div v-else>
        <div v-if="success.is">
          <Alert type="success" :text="success.message" icon="check" />
        </div>
        <div v-if="error.is">
          <Alert type="danger" :text="error.message" icon="times" />
        </div>
        <div class="u-flex u-mt-medium u-justify-center">
          <Btn :action="handleOpenCommentForm" label="Добавить комментарий" type="secondary" icon="comments" :fullwidth="false" />
        </div> 
    </div>
  </div>
</template>

<script>
import Btn from '@/components/ui/btn.vue';
import TextField from '@/components/ui/Fields/TextField.vue';
import Preloader from '@/components/ui/preloader.vue';
import Alert from '@/components/ui/alert.vue';

import { mapState, mapActions } from 'vuex';

import routes from '@/constants/server.js';
import axios from '@/js/http-client.js';
import moment from 'moment';

export default {
  name: 'LaptopActivity',
  components: {
    Btn,
    TextField,
    Preloader,
    Alert,
  },
  data() {
    return {
      commentFormOpen: false,
      commentText: "",
      loading: false,
      success: {
        is: false,
        message: 'Комментарий добавлен'
      },
      error: {
        is: false,
        message: 'Произошла ошибка при отправке на сервер'
      },
      dynamicComments: [],
    };
  },
  computed: {
    ...mapState('laptop', {
      laptop: state => state.laptop,
    }),
    historyPeriods() {
      let today = [];
      let yesterday = [];
      let earlier = [];

      this.history.map(entry => {
        if (entry.date.split(' ')[0] == this.today) {
          today.push(entry);
        } else if (entry.date.split(' ')[0] == this.yesterday) {
          yesterday.push(entry);
        } else {
          earlier.push(entry);
        }
      });
     
      return [this.dynamicComments.concat(today), yesterday, earlier];
    },
    today() {
      return moment().format('DD.MM.YYYY');
    },
    yesterday() {
      return moment().subtract(1, 'days').format('DD.MM.YYYY');
    },
  },
  methods: {
    ...mapActions('laptop', [
      'fetchData',
    ]),
    prepareEntryTitle(title) {
      return title.replace(/\[(.*?)::(part|laptop)::(\d{1,6})\]/g, '<a href="/#/$2/$3">$1</a>');
    },
    handleOpenCommentForm() {
      this.commentFormOpen = true;
    },
    handleCloseCommentForm() {
      this.commentFormOpen = false;
    },
    handleSubmitCommentForm() {
      this.commentFormOpen = false;
      this.loading = true;
      console.log(this.laptop);
      axios.post(routes.laptop.comment+this.laptop.id, {comment: this.commentText})
        .then(response => {
          console.log(response);
          this.loading = false;
          this.success.is = true;
          this.dynamicComments.unshift(response.data);
          this.clearCommentForm();
          this.setCounter(this.dynamicComments[0]);
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
          this.error.is = true;
        });
    },
    clearCommentForm() {
      setTimeout(() => {
        this.success.is  = false;
        this.error.is    = false;
        this.commentText = "";
      }, 1500);
    },
    setCounter(entry) {
      entry.timerId = setInterval(() => {
        if (!entry.timer) {
          entry.timer = 10000;
        }
        if (entry.timer < 60000) {
          entry.date = `${entry.timer / 1000} секунд назад`;
        } else if (entry.timer < 120000) {
          entry.date = `минуту назад`;
        } else if (entry.timer < 180000) {
          entry.date = `две минуты назад`;
        } else if (entry.timer < 240000) {
          entry.date = `три минуты назад`;
        } else if (entry.timer < 300000) {
          entry.date = `четыре минуты назад`;
        } else if (entry.timer < 360000) {
          entry.date = `пять минут назад`;
        } else {
          entry.date = `Сегодня`;
          clearInterval(entry.timerId);
        }
        entry.timer += 10000;
        
      }, 10000);
    },
    getEntryTime(date) {
      return date.split(' ')[1];
    },
    getEntryColor(type) {
      let color = '';
      switch (type) {
        case 'add_laptop' :
          color = 'c-feed__item--success';
          break;

        case 'edit_laptop' :
        case 'change_laptop_status' :
          color = 'c-feed__item--fancy';
          break;

        case 'add_laptop_comment' :
        case 'install_part' :
        case 'add_work' :
          color = 'c-feed__item--warning';
          break;

        default :
          color = 'c-feed__item--info';
      }
      return color;
    }
  },
  props: {
    history: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="css">
  .c-feed__item--warning::before {
    background-color: #fd9a18!important;
  }

 
</style>