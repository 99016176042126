import axios from 'axios';
import { isAuth, token } from '@/js/helpers.js';

const instance = axios.create({
  baseURL: 'http://noteapp.landingheroes.ru/wp-json',
  // baseURL: 'http://localhost/wp-json',
});

//  instance.defaults.headers.common['Authorization'] = token();
instance.interceptors.request.use(function (config) {
  if (isAuth()) {
    config.headers.common = {...config.headers.common, "Authorization": token()}
  }
  return config;
});

export default instance;
