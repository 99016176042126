<template>
  <td class="c-table__cell">
    {{ value }}
  </td>
</template>
<script>
export default {
  name: 'Os',
  computed: {
    value() {
      return this.data.value;
    },
    label() {
      return this.data.label;
    },
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>