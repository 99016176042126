<template>
  <div class="row u-mb-large">
    <div class="col-sm-12">
        <div class="c-table-responsive@desktop">
          <table class="c-table c-table--highlight">
            <caption class="c-table__title">
                  Целевые действия
                  <span v-if="action" class="c-table__title-action">
                    <component :is="action"></component>
                  </span>
              </caption>
            <thead class="c-table__head c-table__head--slim">
                <tr class="c-table__row">   
                    <!-- <th class="c-table__cell c-table__cell--head">id</th> -->
                    <th class="c-table__cell c-table__cell--head">Наименование</th>
                    <th class="c-table__cell c-table__cell--head">Тип действия</th>
                    <th class="c-table__cell c-table__cell--head">Сумма</th>
                    <!-- <th class="c-table__cell c-table__cell--head">
                      <span class="u-hidden-visually">Действия</span>
                    </th> -->
                </tr>
            </thead>
            <tbody>
                <tr
                  v-for="(item, key) in items"
                  :key="key"
                  class="c-table__row"
                >
                  <td class="c-table__cell">
                    {{ item.title }}
                  </td>
                  <td class="c-table__cell">
                    {{ getActionLabel(item.action) }}
                  </td>
                  <td class="c-table__cell">
                    <span v-if="getActionLabel(item.action)=='Продажа ноутбука'">{{ item.sum }}%</span>
                    <span v-else>{{ moneyFormat(item.sum) }}</span>
                  </td>
                </tr>
            </tbody>
          </table>
        </div>
    </div>
  </div>
</template>

<script>
import { getActionLabel, moneyFormat } from '@/js/helpers.js';

export default {
  name: 'PayableActionsTable',
  props: {
    items: {
      type: Array,
      required: true
    },
    action: {
      type: [Object, Boolean],
      default: false
    }
  },
  methods: {
    getActionLabel,
    moneyFormat,
  }
}
</script>

<style lang="css">
  .c-badge {
    font-size: 10px;
    font-weight: 500;
    margin-right: 5px;
    margin-bottom: 5px;
  }
  .c-table__fields-cell {
    max-width: 300px;
    display: flex;
    flex-wrap: wrap;
  }
  .c-table__icon-cell {
    font-size: 20px;
    opacity: .5;
  }
</style>