<template>
  <td class="c-table__cell">
    <p class="c-feed__comment">
      <i class="fa fa-comments-o" style="opacity: 0.5;"></i> {{ text }}
    </p>
  </td>
</template>
<script>
export default {
  name: 'Comment',
  computed: {
    text() {
      return this.data.text;
    },
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>