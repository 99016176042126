<template>
  <div :class="['c-dropdown__menu', 'dropdown-menu'].concat(show ? ['show'] : [])">
    <a v-for="(link, key) in links" :key="key" class="c-dropdown__item dropdown-item" :href="link.href"
       @click.stop="link.action">{{ link.label }}</a>
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  props: {
    links: {
      type: Array,
      default: () => {
        return []
      },
    },
    show: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style lang="css">
.dropdown-menu.show {
  visibility: visible;
  opacity: 1;
}
</style>