import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import SingleLaptop from '../views/SingleLaptop.vue';
import SingleUser from '../views/SingleUser.vue';
import Laptops from '../views/Laptops.vue';
import Views from '../views/Views.vue';
import Users from '../views/Users.vue';
import Parts from '../views/Parts.vue';
import PayableActions from '../views/PayableActions.vue';

import { isAuth } from '@/js/helpers.js';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    //component: Parts,
    beforeEnter: (to, from, next) => {
      console.log(to, from);
      if (!isAuth()) {
        next('/login');
      } else {
        next('/views/');
      }
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/laptop/:id',
    name: 'Ноутбук',
    component: SingleLaptop,
    beforeEnter: (to, from, next) => {
      console.log(to, from);
      if (!isAuth()) {
        next('/login');
      } else {
        next();
      }
    },
  },
  {
    path: '/user/:id',
    name: 'Сотрудник',
    component: SingleUser,
    beforeEnter: (to, from, next) => {
      console.log(to, from);
      if (!isAuth()) {
        next('/login');
      } else {
        next();
      }
    },
  },
  {
    path: '/laptops/:view',
    name: 'Ноутбуки',
    component: Laptops,
    beforeEnter: (to, from, next) => {
      console.log(to, from);
      if (!isAuth()) {
        next('/login');
      } else {
        next();
      }
    },
  },
  {
    path: '/views/',
    name: 'Представления',
    component: Views,
    beforeEnter: (to, from, next) => {
      console.log(to, from);
      if (!isAuth()) {
        next('/login');
      } else {
        next();
      }
    },
  },
  {
    path: '/parts/',
    name: 'Запчасти',
    component: Parts,
    beforeEnter: (to, from, next) => {
      console.log(to, from);
      if (!isAuth()) {
        next('/login');
      } else {
        next();
      }
    },
  },
  {
    path: '/payable-actions/',
    name: 'Целевые действия',
    component: PayableActions,
    beforeEnter: (to, from, next) => {
      console.log(to, from);
      if (!isAuth()) {
        next('/login');
      } else {
        next();
      }
    },
  },
  {
    path: '/users/',
    name: 'Сотрудники',
    component: Users,
    beforeEnter: (to, from, next) => {
      console.log(to, from);
      if (!isAuth()) {
        next('/login');
      } else {
        next();
      }
    },
  },
  
]

const router = new VueRouter({
  routes
})

export default router;
