<template>
  <td class="c-table__cell">
    <span v-for="(hard, i) in hards" :key="i">
      <span v-if="hard.turns" class="c-tooltip c-tooltip--top" :aria-label="hard.turns ? 'Обороты: '+hard.turns : ''"><i class="fa fa-hdd-o" style="color: black;"></i> {{ hard.tip.toUpperCase() }}, {{ hard.volume }} GB, {{ hard.smart }}%*</span>
      <span v-else><i class="fa fa-hdd-o" style="color: black;"></i> {{ hard.tip.toUpperCase() }}, {{ hard.volume }} GB, {{ hard.smart }}%</span>
      <br>
    </span>
  </td>
</template>
<script>
export default {
  name: 'Hards',
  computed: {
    hards() {
      return this.data.hards;
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>