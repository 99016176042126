<template>
  <div>
    <div class="c-modal__subheader u-pv-zero">
      <TabHeading title="Общая информация" target="info" :active="isActive('info')" :onClick="switchTab"/>
      <TabHeading title="Технические характеристики" target="tech" :active="isActive('tech')" :onClick="switchTab"/>
    </div>
    <div class="c-modal__body c-modal__body--maximized">
      <ModalPreloader v-if="loading"/>
      <TabItem v-show="isActive('info')">
        <InfoTabForm ref="infoForm"/>
      </TabItem>
      <TabItem v-show="isActive('tech')">
        <TechTabForm ref="techForm"/>
      </TabItem>
    </div>
    <div class="c-modal__footer text-right" v-if="!error.is && !success.is">
      <Btn
          label="Назад"
          :action="prev"
          :disabled="isFirstTabActive"
          type="secondary"
          :fullwidth="false"
      />
      <Btn
          label="Далее"
          :action="next"
          v-if="!isLastTabActive"
          type="info"
          :fullwidth="false"
      />
      <Btn
          label="Сохранить"
          icon="floppy-o"
          :action="handleSave"
          v-else
          type="success"
          :fullwidth="false"
      />
    </div>
    <div class="c-modal__footer text-right" v-else>
      <Alert v-if="success.is" type="success" icon="check" :text="success.message"/>
      <Alert v-if="error.is" type="danger" icon="times" :text="error.message"/>
    </div>
  </div>

</template>

<script>
import TabHeading from '@/components/ui/Tabs/tab-heading.vue';
import TabItem from '@/components/ui/Tabs/tab-item.vue';
import InfoTabForm from '@/components/ui/Tabs/AddLaptopTabs/InfoTabForm';
import TechTabForm from '@/components/ui/Tabs/AddLaptopTabs/TechTabForm';

import Btn from '@/components/ui/btn.vue';
import axios from '@/js/http-client.js';
import routes from '@/constants/server.js';
import {desobserve} from '@/js/helpers.js';
import Alert from '@/components/ui/alert.vue';
import ModalPreloader from '@/components/ui/modal-preloader.vue';

export default {
  name: 'AddLaptopTabs',
  data() {
    return {
      activeTab: 'info',
      tabs: ['info', 'tech'],
      error: {
        is: false,
        message: 'Произошла ошибка при обращении к серверу',
      },
      success: {
        is: false,
        message: 'Ноутбук успешно добавлен',
      },
      loading: false,
    };
  },
  methods: {
    async handleSave() {
      this.loading = true;
      if (!this.isFormHasErrors()) {
        await this.saveLaptop();
        await this.storeParts(this.formData);
      }
    },
    async saveLaptop() {
      return axios.post(routes.laptop.save, this.formData)
          .then((response) => {
            console.log(response.data);
            this.error.is = false;
            this.success.is = true;
            this.loading = false;
            setTimeout(this.clear.bind(this), 2000);
          })
          .catch((error) => {
            console.log(error);
            this.error.is = true;
            this.loading = false;
            setTimeout(this.clear.bind(this), 2000);
          });
    },
    async storeParts(laptopData) {
      // CPU
      if (laptopData.cpuModel.value !== '') {
        let cpuPart = this.buildPart(laptopData.cpuModel.value, 'Процессор', 'Производитель: ' + laptopData.cpuModel.value.split(' ')[0].toLowerCase() + '\r\n'
            + 'Ядра: ' + laptopData.cpuCores.value + '\r\n' + 'Частота: min: ' + laptopData.cpuMinFreq.value +
            ' max: ' + laptopData.cpuMaxFreq.value, laptopData.title.value);
        await this.saveAndInstallPart(cpuPart);
      }
      // GPU
      if (laptopData.gpuModel.value !== '') {
        let gpuPart = this.buildPart(laptopData.gpuModel.value, 'Видеокарта', 'Производитель: ' + laptopData.gpuModel.value.split(' ')[0].toLowerCase() + '\r\n'
            + 'Объем: ' + laptopData.gpuMemory.value + 'Gb' + '\r\n' + 'Частота: ' +
            laptopData.gpuFreq.value, laptopData.title.value);
        await this.saveAndInstallPart(gpuPart);
      }
      // AKB
      if (laptopData.akb.value !== '') {
        let akbPart = this.buildPart(laptopData.title.value, 'Аккумулятор', laptopData.akb.value, laptopData.title.value);
        await this.saveAndInstallPart(akbPart);
      }
      // ram
      if (laptopData.ramVendor.value !== '') {
        let ramPart = this.buildPart(laptopData.ramVendor.value, 'ОЗУ', 'Тип: ' + laptopData.ramType.value + '\r\n'
            + 'Объем: ' + laptopData.ramMemory.value + 'Gb' + '\r\n' + 'Частота: ' +
            laptopData.ramFreq.value, laptopData.title.value);
        await this.saveAndInstallPart(ramPart);
      }
      // matrix
      if (laptopData.screenResolution.value !== '') {
        let screenPart = this.buildPart(laptopData.screenMatrix.value, 'Матрица', 'Разрешение: ' + laptopData.screenResolution.value + '\r\n'
            + 'Диагональ: ' + laptopData.screenDiagonal.value + '\r\n' + 'Покрытие: ' +
            laptopData.screenType.value, laptopData.title.value);
        await this.saveAndInstallPart(screenPart);
      }
      // hards
      if (laptopData.hards[0][0].value !== '' || laptopData.hards[0][0].value === 'undefined') {
        for (let hard in laptopData.hards) {
          let hardPart = this.buildPart(laptopData.hards[hard][0].value, 'Жесткий диск', 'Тип: ' +
              laptopData.hards[hard][1].value + '\r\n'
              + 'Объем: ' + laptopData.hards[hard][2].value + 'Gb' + '\r\n' + 'Обороты: ' +
              laptopData.hards[hard][3].value + '\r\n' +
              'SMART: ' +
              laptopData.hards[hard][4].value, laptopData.title.value);
          await this.saveAndInstallPart(hardPart);
        }
      }
    },
    async saveAndInstallPart(partData) {
      return axios.post(routes.parts.save, partData)
          .then(response => {
            console.log(response);
            this.success.is = true;
            this.loading = false;
          })
          .catch(error => {
            console.log(error);
            this.error.is = true;
            this.loading = false;
          });
    },
    buildPart(title, type, content, laptop) {
      let part = {};
      Object.assign(part, this.blankPart);
      part.title = title;
      part.type = type;
      part.content = content;
      part.laptop = laptop;
      part.isLaptopNative = true;

      return part;
    },
    isFormHasErrors() {
      this.error.is = false;
      // info tab
      if (this.formData.status.value === "") {
        this.setError(this.formData.status.error, 'info');
      }
      if (this.formData.brand.value === "") {
        this.setError(this.formData.brand.error, 'info');
      }
      if (this.formData.title.value === "") {
        this.setError(this.formData.title.error, 'info');
      }
      if (this.formData.model.value === "") {
        this.setError(this.formData.model.error, 'info');
      }
      // if (this.formData.sku.value === "") {
      //   this.setError(this.formData.sku.error, 'info');
      // }
      if (this.formData.buyChannel.value === "") {
        this.setError(this.formData.buyChannel.error, 'info');
      }
      if (this.formData.buyer.value === "") {
        this.setError(this.formData.buyer.error, 'info');
      }
      if (this.formData.buyPrice.value === "") {
        this.setError(this.formData.buyPrice.error, 'info');
      }
      if (this.formData.state.value === "") {
        this.setError(this.formData.state.error, 'info');
      }

      // tech tab
      // cpu
      if (this.formData.cpuModel.value === "") {
        this.setError(this.formData.cpuModel.error, 'tech');
      }
      if (this.formData.cpuMinFreq.value === "") {
        this.setError(this.formData.cpuMinFreq.error, 'tech');
      }
      if (this.formData.cpuMaxFreq.value === "") {
        this.setError(this.formData.cpuMaxFreq.error, 'tech');
      }
      if (this.formData.cpuCores.value === "") {
        this.setError(this.formData.cpuCores.error, 'tech');
      }
      // os
      // if (this.formData.os.value === "") {
      //   this.setError(this.formData.os.error, 'tech');
      // }
      // akb
      // if (this.formData.akb.value === "") {
      //   this.setError(this.formData.akb.error, 'tech');
      // }
      // ram
      if (this.formData.ramMemory.value === "") {
        this.setError(this.formData.ramMemory.error, 'tech');
      }
      if (this.formData.ramType.value === "") {
        this.setError(this.formData.ramType.error, 'tech');
      }
      if (this.formData.gpuModel.value === "") {
        this.setError(this.formData.gpuModel.error, 'tech');
      }
      if (this.formData.gpuMemory.value === "") {
        this.setError(this.formData.gpuMemory.error, 'tech');
      }
      // screen
      if (this.formData.screenDiagonal.value === "") {
        this.setError(this.formData.screenDiagonal.error, 'tech');
      }
      if (this.formData.screenResolution.value === "") {
        this.setError(this.formData.screenResolution.error, 'tech');
      }
      if (this.formData.screenType.value === "") {
        this.setError(this.formData.screenType.error, 'tech');
      }
      if (this.formData.screenMatrix.value === "") {
        this.setError(this.formData.screenMatrix.error, 'tech');
      }

      return this.error.is;
    },
    setError(message, tab) {
      this.error.is = true;
      this.error.message = message;
      this.loading = false;
      this.activeTab = tab;
      setTimeout(() => this.error.is = false, 2000)
    },
    switchTab(target) {
      this.activeTab = target;
    },
    isActive(tab) {
      return this.activeTab === tab;
    },
    next() {
      if (!this.isLastTabActive) {
        this.activeTab = this.tabs[this.nextTabIndex];
      }
    },
    prev() {
      if (!this.isFirstTabActive) {
        this.activeTab = this.tabs[this.prevTabIndex];
      }
    },
    clear() {
      this.success.is = false;
      this.error.is = false;
      this.$emit('clear-form');
    },
  },
  computed: {
    formData() {
      return desobserve(Object.assign({}, this.$refs.infoForm._data, this.$refs.techForm._data));
    },
    activeTabIndex() {
      return this.tabs.indexOf(this.activeTab);
    },
    nextTabIndex() {
      return this.tabs.indexOf(this.activeTab) + 1;
    },
    prevTabIndex() {
      return this.tabs.indexOf(this.activeTab) - 1;
    },
    isFirstTabActive() {
      return this.activeTabIndex === 0;
    },
    isLastTabActive() {
      return this.activeTabIndex === this.tabs.length - 1;
    },
    blankPart() {
      return {
        title: '',
        type: '',
        condition: 'Рабочее',
        price: 0,
        qty: 1,
        laptop: '',
        content: '',
      }
    }
  },
  components: {
    TabHeading,
    TabItem,
    InfoTabForm,
    TechTabForm,
    Btn,
    Alert,
    ModalPreloader,
  },
  props: {}
}
</script>

<style lang="css">
.c-modal__subheader-tab {
  margin-right: 35px;
}
</style>
