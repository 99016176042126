<template>
  <div class="o-page__sidebar js-page-sidebar">
    <div class="c-sidebar">
        <a class="c-sidebar__brand" href="#">
            <img class="c-sidebar__brand-img" src="/img/logo.png" alt="Logo">
            <!-- <span class="c-sidebar__brand-text">NoteCRM</span> -->
            <Logo color="invert" size="small" />
        </a>
        <SidebarTitle title="Ноутбуки" />
        <SidebarList :items="navigation.laptops" />
        <span v-if="isAdmin()">
            <SidebarTitle title="Администрирование" />
            <SidebarList :items="navigation.views" />
            <SidebarList :items="navigation.users" />
            <SidebarList :items="navigation.parts" />
            <SidebarList :items="navigation.payableActions" />
        </span>

    </div><!-- // .c-sidebar -->
  </div><!-- // .o-page__sidebar -->
</template>
<script>
import { mapState, mapActions } from 'vuex';
import Logo from '@/components/brand/logo.vue';
import SidebarTitle from '@/components/layout/Sidebar/sidebar-title.vue';
import SidebarList from '@/components/layout/Sidebar/sidebar-list.vue';
//import SidebarItem from '@/components/layout/sidebar/sidebar-item.vue';
import { getUser, isAdmin } from '@/js/helpers.js';

export default {
    name: 'Sidebar',
    mounted() {
        this.getLaptopsNav();
        console.log(getUser());
    },
    computed: {
        ...mapState('app', {
            navigation: state => state.navigation
        }),
    },
    methods: {
        ...mapActions('app', [
            'getLaptopsNav',
        ]),
        isAdmin,
    },
    components: {
        Logo,
        SidebarTitle,
        SidebarList,
        //SidebarItem,
    },
}
</script>