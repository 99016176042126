import axios from '@/js/http-client.js';
import routes from '@/constants/server.js';
import AddViewModal from '@/components/ui/Modals/AddViewModal.vue';

export default {
    namespaced: true,
    state: {
        action: AddViewModal,
        loading: true,
        error: false,
        opened: false,
        viewObj: false,
        table: {
            total: 0,
            items: [],
            perPage: 10,
            current: 1,
            pages: 1
        }
    },
    getters: {
        action: state => state.action,
        loading: state => state.loading,
        error: state => state.error,
        table: state => state.table,
        opened: state => state.opened,
        viewObj: state => state.viewObj,
    },
    mutations: {
        startLoading: state => state.loading = true,
        stopLoading: state => state.loading = false,
        setTable: (state, table) => state.table = table,
        setError: state => state.error = true,
        unsetError: state => state.error = false,
        openModal: state => state.opened = true,
        closeModal(state) {
            state.opened = false;
            state.viewObj = false;
        },
        setObj: (state, viewObj) => state.viewObj = viewObj,
    },
    actions: {
        fetchData(context) {
            context.commit('startLoading');

            const endpoint = `${routes.view.list}?perPage=${context.state.table.perPage}&current=${context.state.table.current}`;
            axios.get(endpoint)
                .then((response) => {
                    console.log(response);
                    context.commit('setTable', response.data);
                    context.commit('stopLoading');
                })
                .catch((error) => {
                    console.log(error);
                    this.error = true;
                    context.commit('stopLoading');
                });
        },
        setViewObj(context, viewObj) {
            context.commit('setObj', viewObj);
        }
    }
}