<template>
  <div :class="['c-toolbar', 'u-mb-large', 'c-laptop-filters-panel', open ? 'is-open' : '', hasFilters ? 'has-filters' : '']">
    <h5 @click="toggle" class="c-toolbar__title u-text-small u-text-mute u-mr-medium c-laptop-filters__switch">
      <i class="fa fa-filter" style="margin-right:5px; opacity:.5"></i>&nbsp;
      Фильтры
    </h5>
    <div class="row">
      <div class="container-fluid">
        <div class="row">
          <div class="col-2 c-laptop-filters mb15">
            <TextField
              v-model="filters.status.value"
              :icon="filters.status.icon"
              :placeholder="filters.status.placeholder"
              :endpoint="filters.status.endpoint"
            />
          </div>
          <div class="col-2 c-laptop-filters mb15">
            <TextField
              v-model="filters.cpu.value"
              :icon="filters.cpu.icon"
              :placeholder="filters.cpu.placeholder"
              :endpoint="filters.cpu.endpoint"
            />
          </div>
          <div class="col-2 c-laptop-filters mb15">
            <TextField
              v-model="filters.gpu.value"
              :icon="filters.gpu.icon"
              :placeholder="filters.gpu.placeholder"
              :endpoint="filters.gpu.endpoint"
            />
          </div>
          <div class="col-2 c-laptop-filters mb15">
            <TextField
              v-model="filters.diagonal.value"
              :icon="filters.diagonal.icon"
              :placeholder="filters.diagonal.placeholder"
              :endpoint="filters.diagonal.endpoint"
            />
          </div>
          <div class="col-2 c-laptop-filters mb15">
            <TextField
              v-model="filters.resolution.value"
              :icon="filters.resolution.icon"
              :placeholder="filters.resolution.placeholder"
              :endpoint="filters.resolution.endpoint"
            />
          </div>
          <div class="col-2 c-laptop-filters">
            <Btn type="info" label="Применить" icon="search" :action="handleSubmit" :disabled="!this.hasFilters" />
          </div>
          <div class="col-2 c-laptop-filters">
            <TextField
              v-model="filters.brand.value"
              :icon="filters.brand.icon"
              :placeholder="filters.brand.placeholder"
              :endpoint="filters.brand.endpoint"
            />
          </div>
          <div class="col-2 c-laptop-filters">
            <TextField
              v-model="filters.ram.value"
              :icon="filters.ram.icon"
              :placeholder="filters.ram.placeholder"
            />
          </div>
          <div class="col-2 c-laptop-filters">
            <TextField
              v-model="filters.akb.value"
              :icon="filters.akb.icon"
              :placeholder="filters.akb.placeholder"
            />
          </div>
          <div class="col-2 c-laptop-filters">
            <TextField
              v-model="filters.year.value"
              :icon="filters.year.icon"
              :placeholder="filters.year.placeholder"
              :options="filters.year.options"
            />
          </div>
          <div class="col-2 c-laptop-filters">
            <TextField
              v-model="filters.buyChannel.value"
              :icon="filters.buyChannel.icon"
              :placeholder="filters.buyChannel.placeholder"
              :options="filters.buyChannel.options"
            />
          </div>
          <div class="col-2 c-laptop-filters">
            <Btn v-show="this.filtered" type="secondary" label="Очистить" icon="times" :action="handleClear" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextField from '@/components/ui/Fields/TextField.vue';
import Btn from '@/components/ui/btn.vue';

import { mapActions } from 'vuex';
import { getYears } from '@/js/helpers.js';

import routes from '@/constants/server.js';

export default {
  name: 'LaptopFilters',
  components: {
    TextField,
    Btn,
  },
  methods: {
    ...mapActions('laptops', [
      'fetchFilteredData',
      'fetchData',
    ]),
    toggle() {
      this.open = !this.open;
      console.log(this);
    },
    handleSubmit() {
      this.fetchFilteredData(
        {
          view: this.view,
          filters: this.activeFilters,
          router: this.$router,
        }
      );
      this.filtered = true;
    },
    handleClear() {
      this.fetchData(this.view);
      for (let key in this.filters) {
        this.filters[key].value = "";
      }
      this.filtered = false;
    }
  },
  computed: {
    activeFilters() {
      let filters = [];
      for (let filter in this.filters) {
        if (this.filters[filter].value.length > 0) {
          filters.push(this.filters[filter]);
        }
      }
      return filters;
    },
    hasFilters() {
      return this.activeFilters.length > 0;
    },
    view() {
      const { view } = this.$route.params;
      return view;
    }
  },
  data() {
    return {
      open: false,
      filtered: false,
      filters: {
        status: {
          value: '',
          icon: 'circle-o',
          term: 'status',
          placeholder: 'Статус',
          endpoint: `${routes.search}status/`,
          operator: 'EQUAL',
        },
        cpu: {
          value: '',
          icon: 'microchip',
          term: 'cpu-model',
          placeholder: 'Процессор',
          endpoint: `${routes.searchExisting}cpu-model/`,
          operator: 'LIKE',
        },
        gpu: {
          value: '',
          icon: 'tachometer',
          term: 'gpu-model',
          placeholder: 'Видеокарта',
          endpoint: `${routes.searchExisting}gpu-model/`,
          operator: 'LIKE',
        },
        diagonal: {
          value: '',
          icon: 'crop',
          term: 'screen-diagonal',
          placeholder: 'Диагональ',
          endpoint: `${routes.searchExisting}screen-diagonal/`,
          operator: 'LIKE',
        },
        resolution: {
          value: '',
          icon: 'television',
          term: 'screen-resolution',
          placeholder: 'Разрешение',
          endpoint: `${routes.searchExisting}screen-resolution/`,
          operator: 'LIKE',
        },
        brand: {
          value: '',
          icon: 'apple',
          term: 'brand',
          placeholder: 'Бренд',
          endpoint: `${routes.searchExisting}brand/`,
          operator: 'LIKE',
        },
        ram: {
          value: '',
          icon: 'ticket',
          meta: 'ram_memory',
          placeholder: 'Оперативка',
          operator: 'LIKE',
        },
        akb: {
          value: '',
          icon: 'battery-full',
          meta: 'akb',
          placeholder: 'АКБ',
          operator: 'LIKE',
        },
        year: {
          value: '',
          icon: 'calendar',
          meta: 'year',
          placeholder: 'Год',
          options: getYears(),
          operator: 'EQUAL',
        },
        buyChannel: {
          value: '',
          icon: 'arrow-circle-right',
          term: 'buyChannel',
          placeholder: 'Канал покупки',
          endpoint: `${routes.searchExisting}buy-channel/`,
          operator: 'LIKE',
        },
      }, 
    };
  }
}
</script>

<style lang="css">
  .u-mtm-medium {
    margin-top: -1.875rem!important;
  }

  .c-laptop-filters {
    height: 36px;
  }

  .c-laptop-filters__switch {
    position: absolute;
    bottom: -31px;
    background-color: white;
    padding: 5px 20px;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.07);
    left: 17px;
    border-radius: 0 0 4px 4px;
    cursor: pointer;
  }

  .c-laptop-filters__switch::after {
    content:"\f0d7";
    font-family: FontAwesome;
    position: relative;
    left: 5px;
    top: 1px;
    opacity: .5;
    
  }

  .c-laptop-filters-panel {
    position: relative;
    z-index: 3;
    margin-top: -158px;
    transition: .3s linear;
  }

  .c-laptop-filters-panel.is-open {
    margin-top: -30px;
  }

  .c-laptop-filters-panel.is-open .c-laptop-filters__switch::after {
    content:"\f0d8";
  }

  .c-laptop-filters-panel.has-filters .c-laptop-filters__switch i {
    opacity: 1 !important;
    color: #ff7800;
  }

  .c-laptop-filters-panel.has-filters .c-laptop-filters__switch {
    color: #ff7800 !important;
  }

</style>