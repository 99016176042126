<template>
  <div class="c-icon-field">
    <div class="c-icon-field__icon">
      <i :class="['fa', value]"></i>
    </div>
    <TextField
      v-model="value"
      :options="options"
      legend="Выберите иконку из списка"
      label="Иконка"
      :optionsAsIcons="true"
     />
  </div>
</template>

<script>
import TextField from './TextField';
import icons from '@/constants/icons.js';

export default {
  name: 'IconField',
  components: {
    TextField,
  },
  data() {
    return {
      options: icons,
      value: 'fa-laptop'
    }
  }
}
</script>

<style lang="css">
  .c-icon-field {
    position: relative;
  }
  .c-icon-field__icon {
    position: absolute;
    left: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 25px;
    height: 39px;
    z-index: 200;
  }
  .c-icon-field .c-input {
    padding-left: 50px;
  }

</style>