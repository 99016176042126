<template>
  <div class="row u-mb-large">
    <div class="col-sm-12">
      <div class="c-table-responsive@desktop">
        <table class="c-table c-laptops-table c-table--highlight u-mb-small">

          <caption v-if="hasItems" class="c-table__title">
            {{ title }} <small>Всего ноутбуков: {{ total }}</small>
            <span v-if="action" class="c-table__title-action">
                      <component :is="action"></component>
                    </span>
          </caption>

          <caption v-else class="c-table__title">
            {{ title }} <small>По данному запросу ноутбуков не найдено</small>
            <span v-if="action" class="c-table__title-action">
                      <component :is="action"></component>
                    </span>
          </caption>

          <thead v-if="hasItems" class="c-table__head c-table__head--slim">
          <tr class="c-table__row">
            <th v-for="(header, i) in headers" :key="i" class="c-table__cell c-table__cell--head">{{ header }}</th>
            <th class="c-table__cell c-table__cell--head">Действия</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, i) in items" :key="i" class="c-table__row" @click="$router.push(`/laptop/${row.id}`)">
            <component v-for="(cell, k) in row.cells" :key="k" :is="cell.field" :data="cell.data"></component>
            <td>
              <DropdownMenu :links="getLinks(row.id)" label="Действия"/>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import Title from '@/components/table/fields/Title.vue';
import Created from '@/components/table/fields/Created.vue';
import Model from '@/components/table/fields/Model.vue';
import Screen from '@/components/table/fields/Screen.vue';
import Cpu from '@/components/table/fields/Cpu.vue';
import Gpu from '@/components/table/fields/Gpu.vue';
import Ram from '@/components/table/fields/Ram.vue';
import Hards from '@/components/table/fields/Hards.vue';
import Os from '@/components/table/fields/Os.vue';
import Status from '@/components/table/fields/Status.vue';
import Buy from '@/components/table/fields/Buy.vue';
import Repair from '@/components/table/fields/Repair.vue';
import Akb from '@/components/table/fields/Akb.vue';
import Sell from '@/components/table/fields/Sell.vue';
import Comment from '@/components/table/fields/Comment.vue';
import DropdownMenu from '@/components/ui/dropdown-menu.vue';
import axios from '@/js/http-client.js';
import routes from '@/constants/server.js';

export default {
  name: 'LaptopsTable',
  components: {
    Title,
    Created,
    Model,
    Screen,
    Cpu,
    Gpu,
    Ram,
    Hards,
    Os,
    Status,
    Buy,
    Repair,
    Sell,
    Akb,
    Comment,
    DropdownMenu,
  },
  computed: {
    hasItems() {
      return this.items !== null && this.items.length > 0;
    }
  },
  props: {
    title: {
      type: String,
      required: true
    },
    total: {
      type: Number,
      default: 0
    },
    items: {
      validator: prop => typeof prop === 'object' || prop === null,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    action: {
      type: [Object, Boolean],
      default: false,
    }
  },
  methods: {
    handleCloneLaptop(id) {
      let numberOfClones = prompt('Сколько копий ноутбука добавить?', 1);

      if (numberOfClones) {
        let data = {
          'numberOfClones': numberOfClones,
          'laptopId': id,
        };
        axios.post(routes.laptop.clone, data)
            .then(response => {
              console.log('CLONE RESPONSE')
              console.log(response);
              this.$emit('refresh-laptops');
            })
            .catch(error => {
              console.log('CLONE RESPONSE ERROR')
              console.log(error);
            });
      }
    },
    getLinks(id) {
      return [
        {
          label: "Клонировать",
          href: "/#",
          action: () => {
            this.handleCloneLaptop(id);
          },
        },
      ];
    },
  }
}
</script>

<style lang="css">
.c-laptops-table tr {
  cursor: pointer;
}
</style>