<template>
  <td class="c-table__cell">
    <i class="fa fa-wrench" style="opacity: .5;"></i> {{ work }} ₽
    <span class="c-tooltip c-tooltip--top" style="margin-left: 10px" :aria-label="description">
      <i class="fa fa-cogs" style="opacity: .5"></i> {{ parts }} ₽*
    </span>
    <small class="u-block u-text-mute">
      <i class="fa fa-user-o mr5"></i>{{ user }}
    </small>
  </td>
</template>
<script>
export default {
  name: 'Repair',
  computed: {
    work() {
      return (+this.data.work).toLocaleString();
    },
    parts() {
      return (+this.data.parts).toLocaleString();
    },
    description() {
      return this.data.description;
    },
    user() {
      return this.data.user;
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>