<template>
  <div class="c-btn--fullwidth u-mr-small">
    <Btn label="Установить запчасти" type="info" icon="cogs" :action="handleOpen" :fullwidth="false" />
    <Modal :open="open" :title="title" :handleClose="handleClose" size="xlarge">
      <InstallPartsForm @clear-form="open=false" />
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/ui/modal.vue';
import Btn from '@/components/ui/btn.vue';
import InstallPartsForm from '@/components/forms/InstallPartsForm.vue';

export default {
  name: 'InstallPartsModal',
  components: {
    InstallPartsForm,
    Modal,
    Btn,
  },
  data() {
    return {
      open: false,
      title:  "Установка запчастей"
    };
  },
  methods: {
    handleOpen() {
      this.open = true;
    },
    handleClose() {
      this.open = false;
    },
  }
}
</script>