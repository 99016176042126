<template>
  <span :class="['c-logo', colorClass, sizeClass]">NoteCRM</span>
</template>

<script>
export default {
  name: 'Logo',
  computed: {
    colorClass() {
      return `u-color-${this.color}`;
    },
    sizeClass() {
      return `c-logo--${this.size}`;
    }
  },
  props: {
    color: {
      default: 'primary',
      validator: value => {
        return [
          'success',
          'info',
          'warning',
          'danger',
          'primary',
          'secondary',
          'invert'
        ].indexOf(value) !== -1;
      }
    },
    size: {
      default: 'large',
      validator: value => {
        return [
          'tiny',
          'xsmall',
          'small',
          'large',
        ].indexOf(value) !== -1;
      }
    },
  }
}
</script>

<style lang="css">
  @font-face {
    font-family: 'LeckerliOne';
    src: url('/fonts/LeckerliOne.woff2') format('woff2'),
        url('/fonts/LeckerliOne.woff') format('woff'),
        url('/fonts/LeckerliOne.svg#LeckerliOne') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  .c-logo {
    font-family: 'LeckerliOne';
  }

  .c-logo--tiny {
    font-size: 14px;
  }

  .c-logo--xsmall {
    font-size: 16px;
  }

  .c-logo--small {
    font-size: 20px;
  }

  .c-logo--large {
    font-size: 32px;
  }

  .u-color-invert {
    color: white;
  }
</style>
