export default {
  /***
		Basic Validation Func
		***/
  validateField: function(f) {
    // console.log( f)
    if (typeof f.validate !== "undefined") {
      // console.log(f.validate)
      // Required fields checks if value is not empty
      if (f.validate.required) {
        // console.log( f )
        if (f.value.length == 0) {
          return {
            error: f.validate.required.error
          };
        }
      }

      // Pattern
      if (f.validate.pattern) {
        const reg = new RegExp(f.validate.pattern.reg);
        // console.log(reg)
        if (!f.value.match(reg)) {
          return {
            error: f.validate.pattern.error
          };
        }
      }

      // Min
      if (f.validate.min) {
        if (f.value.length < f.validate.min.treshold) {
          return {
            error: f.validate.min.error
          };
        }
      }

      // Max
      if (f.validate.max) {
        if (f.value.length > f.validate.max.treshold) {
          return {
            error: f.validate.max.error
          };
        }
      }

      // Is
      if (typeof f.validate.is !== "undefined") {
        // in
        if (f.validate.is.in) {
          if (f.validate.is.in.indexOf(f.value) < 0) {
            return {
              error: f.validate.is.in.error || f.validate.is.error
            };
          }
        }
        // equal
        if (typeof f.validate.is.equal !== "undefined") {
          if (f.validate.is.equal != f.value) {
            return {
              error: f.validate.is.equal.error || f.validate.is.error
            };
          }
        }
        // not
        if (typeof f.validate.is.not !== "undefined") {
          if (f.validate.is.not == f.value) {
            return {
              error: f.validate.is.not.error || f.validate.is.error
            };
          }
        }
        // greaterThan
        if (typeof f.validate.is.greaterThan !== "undefined") {
          // console.log( f.value, f.validate.is.greaterThan, f.validate.is.greaterThan >= f.value )
          if (f.validate.is.greaterThan >= f.value) {
            return {
              error: f.validate.is.greaterThan.error || f.validate.is.error
            };
          }
        }
        // lesserThan
        if (typeof f.validate.is.lesserThan !== "undefined") {
          // console.log( f.value, f.validate.is.lesserThan )
          if (f.validate.is.lesserThan <= f.value) {
            return {
              error: f.validate.is.lesserThan.error || f.validate.is.error
            };
          }
        }
      }

      // Custom callback for validation
      if (f.validate.callback) {
        return f.validate.callback(f.value);
      }

      return true;
    } else {
      return true;
    }
  }
};