<template>
  <div class="row u-mb-large">
    <div class="col-sm-12">
        <div class="c-table-responsive@desktop">
          <table class="c-table c-table--highlight">
            <caption class="c-table__title">
                  Запчасти
                  <span v-if="action && isAdmin()" class="c-table__title-action">
                    <component :is="action"></component>
                  </span>
              </caption>
            <thead class="c-table__head c-table__head--slim">
                <tr class="c-table__row">   
                    <!-- <th class="c-table__cell c-table__cell--head">id</th> -->
                    <th class="c-table__cell c-table__cell--head">Наименование</th>
                    <th class="c-table__cell c-table__cell--head">Тип</th>
                    <th class="c-table__cell c-table__cell--head">Состояние</th>
                    <th class="c-table__cell c-table__cell--head">Цена</th>
                    <th class="c-table__cell c-table__cell--head">Количество</th>
                    <th class="c-table__cell c-table__cell--head">Ноутбук</th>
                    <!-- <th class="c-table__cell c-table__cell--head">
                      <span class="u-hidden-visually">Действия</span>
                    </th> -->
                </tr>
            </thead>
            <tbody>
                <tr
                  v-for="(item, key) in items"
                  :key="key"
                  class="c-table__row"
                >
                  <td class="c-table__cell">
                    {{ item.title }}
                  </td>
                  <td class="c-table__cell">
                    {{ item.type.name }}
                  </td>
                  <td class="c-table__cell">
                    {{ item.condition }}
                  </td>
                  <td class="c-table__cell">
                    {{ item.price }} ₽
                  </td>
                  <td class="c-table__cell">
                    {{ item.qty }} шт.
                  </td>          
                  <td class="c-table__cell">
                    {{ item.laptop ? item.laptop.post_title : '—' }}
                  </td>
                  
                </tr>
            </tbody>
          </table>
        </div>
    </div>
  </div>
</template>

<script>
import { isAdmin } from '@/js/helpers.js';

export default {
  name: 'PartsTable',
  props: {
    items: {
      type: Array,
      required: true
    },
    action: {
      type: [Object, Boolean],
      default: false
    }
  },
  methods: {
    isAdmin,
  }
}
</script>

<style lang="css">
  .c-badge {
    font-size: 10px;
    font-weight: 500;
    margin-right: 5px;
    margin-bottom: 5px;
  }
  .c-table__fields-cell {
    max-width: 300px;
    display: flex;
    flex-wrap: wrap;
  }
  .c-table__icon-cell {
    font-size: 20px;
    opacity: .5;
  }
</style>