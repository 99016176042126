<template>
  <div v-if="! isWorker()">
    <Btn label="Добавить ноутбук" type="success" icon="plus" :action="handleOpen" />
    <Modal :open="open" :title="title" :handleClose="handleClose" icon="laptop" size="huge">
      <AddLaptopTabs @clear-form="open=false" />
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/ui/modal.vue';
import Btn from '@/components/ui/btn.vue';
import AddLaptopTabs from '@/components/ui/Tabs/AddLaptopTabs.vue';
import {isWorker} from '@/js/helpers.js';

export default {
  name: 'AddLaptopModal',
  components: {
    Modal,
    Btn,
    AddLaptopTabs
  },
  data() {
    return {
      open: false,
      title:  "Добавить ноутбук"
    };
  },
  methods: {
    isWorker,
    handleOpen() {
      this.open = true;
    },
    handleClose() {
      this.open = false;
    },
  }
}
</script>