<template>
  <div class="c-rating">
    <i v-for="(n, index) in filled" :key="index" class="c-rating__icon is-active fa fa-star"></i>
    <i v-for="(n, index) in empty" :key="index+100" class="c-rating__icon fa fa-star"></i>
  </div>
</template>

<script>
export default {
  name: 'LaptopState',
  computed: {
    filled() {
      return +this.state;
    },
    empty() {
      return 5 - this.state;
    },
  },
  props: {
    state: {
      type: Number,
      required: true
    }
  }
}
</script>