<template>
  <div>
    <div class="c-modal__body c-modal__body--work">
      <ModalPreloader v-if="loading"/>
      <div class="row mb15">
        <div class="col">
          <h4 class="u-mb-medium">
            Установка цены ноутбука
          </h4>
          <div class="row">
            <div class="col-sm-12">
              <div class="mb15">
                <TextField
                    :label="price.label"
                    v-model="price.value"
                    :placeholder="price.placeholder"
                    :legend="price.legend"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="c-modal__footer u-justify-end">
      <Alert
          v-if="success.is || error.is"
          :type="alert.type"
          :text="alert.text"
          :icon="alert.icon"
      />
      <Btn v-else :action="handleSubmit" icon="check" :label="'Установить цену'" :fullwidth="false" type="success"/>
    </div>
  </div>
</template>

<script>
import TextField from '@/components/ui/Fields/TextField.vue';
import ModalPreloader from '@/components/ui/modal-preloader.vue';
import Alert from '@/components/ui/alert.vue';
import Btn from '@/components/ui/btn.vue';
import routes from '@/constants/server.js';
import axios from '@/js/http-client.js';
import {mapActions, mapState} from 'vuex';

export default {
  name: 'AddWorkForm',
  components: {
    TextField,
    ModalPreloader,
    Alert,
    Btn,
  },
  methods: {
    async handleSubmit() {
      this.loading = true;
      let sellData = {
        price: parseInt(this.price.value),
        laptop: this.laptop.id,
        status: 'На продаже',
      };
      await this.handleSave(sellData);
      this.loading = false;
      setTimeout(this.clear.bind(this), 2000);
    },
    async handleSave(data) {
      return axios.post(routes.laptop.setSell, data)
          .then(response => {
            console.log(response);
            this.success.is = true;
          })
          .catch(error => {
            console.log(error);
            this.error.is = true;
          });
    },
    clear() {
      this.success.is = false;
      this.error.is = false;
      this.$emit('clear-form');
      setTimeout(() => {
        this.fetchData(this.laptop.id);
      }, 300);
    },
    ...mapActions('laptop', [
      'fetchData',
    ]),
  },
  computed: {
    ...mapState({
      laptop: state => state.laptop.laptop,
    }),
    formData() {
      return {
        price: parseInt(this.price.value),
        description: this.description.value,
        laptop: this.laptop.id,
      }
    },
    alert() {
      let alert;
      if (this.success.is) {
        alert = {
          type: 'success',
          icon: 'check',
          text: this.success.message,
        };
      }
      if (this.error.is) {
        alert = {
          type: 'danger',
          icon: 'times',
          text: this.error.message,
        };
      }
      return alert;
    }
  },
  data() {
    return {
      totalCost: 0,
      loading: false,
      options: {},
      success: {
        message: 'Цена установлена',
        is: false,
      },
      error: {
        message: 'Произошла ошибка при обращении к серверу',
        is: false,
      },
      price: {
        value: '',
        placeholder: 'Укажите цену ноутбука',
        legend: 'Укажите стоимость ноутбука',
        label: 'Стоимость',
      },
    }
  },
}
</script>

<style lang="css">
.c-modal__body {
  min-height: 500px;
}

.c-modal__footer .c-alert {
  margin-bottom: 0;
}

.c-password-generator {
  width: 26px;
  margin-left: 10px;
  position: relative;
  top: -3px;
  opacity: .7;
  cursor: pointer;
  transition: .2s linear;
}

.c-password-generator:hover {
  opacity: 1;
}

.c-modal__body--work {
  height: 420px;
}

</style>