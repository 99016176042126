<template>
    <ol class="c-breadcrumb mb15">
      <li v-for="(item, key) in items" :key="key" :class="['c-breadcrumb__item', (item.path ? '' : 'is-active')]">
        <a v-if="item.path" :href="item.path">{{ item.title }}</a>
        <span v-else>{{ item.title }}</span>
      </li>
    </ol>
</template>

<script>


export default {
  name: 'Breadcrumbs',
  mounted() {
    console.log(this.$route);
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  }
}
</script>