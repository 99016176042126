<template>
  <div>
    <div class="c-modal__body c-modal__body--part c-modal__spacer">
      <ModalPreloader v-if="loading" />
      <div class="row">
        <div class="col">
          <h4 class="u-mb-medium">
            Новая запчасть
          </h4>
          
          <div class="row">
            <div class="col-sm-6">
              <div class="mb15">
                <TextField
                  :label="title.label"
                  v-model="title.value"
                  :placeholder="title.placeholder"
                  :legend="title.legend"
                />
              </div>
            </div>
            <div class="col-sm-6">
              <div class="mb15">
                <TextField
                  :label="type.label"
                  v-model="type.value"
                  :placeholder="type.placeholder"
                  :legend="type.legend"
                  :endpoint="type.endpoint"
                />
              </div>
            </div>
          </div>

        <div class="row">
            <div class="col-sm-6">
              <div class="mb15">
                <TextField
                  :label="price.label"
                  v-model="price.value"
                  :placeholder="price.placeholder"
                  :legend="price.legend"
                />
              </div>
            </div>
            <div class="col-sm-6">
              <div class="mb15">
                <TextField
                  :label="qty.label"
                  v-model="qty.value"
                  :placeholder="qty.placeholder"
                  :legend="qty.legend"
                  :endpoint="qty.endpoint"
                />
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-sm-6">
              <div class="mb15">
                <TextField
                  :label="condition.label"
                  v-model="condition.value"
                  :placeholder="condition.placeholder"
                  :legend="condition.legend"
                  :options="condition.options"
                />
              </div>
            </div>
            <div class="col-sm-6">
              <div class="mb15">
                <TextField
                  :label="laptop.label"
                  v-model="laptop.value"
                  :placeholder="laptop.placeholder"
                  :legend="laptop.legend"
                  :endpoint="laptop.endpoint"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="mb15">
                <TextField
                  :label="content.label"
                  v-model="content.value"
                  :placeholder="content.placeholder"
                  :legend="content.legend"
                  :textarea="true"
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="c-modal__footer u-justify-end">
      <Alert
        v-if="success.is || error.is"
        :type="alert.type"
        :text="alert.text"
        :icon="alert.icon"
      />
      <Btn v-else :action="handleSubmit" icon="floppy-o" :label="'Сохранить запчасть'" :fullwidth="false" type="success" />
    </div>
  </div>
</template>

<script>
import TextField from '@/components/ui/Fields/TextField.vue';
import ModalPreloader from '@/components/ui/modal-preloader.vue';
import Alert from '@/components/ui/alert.vue';
import Btn from '@/components/ui/btn.vue';
//import fields from '@/constants/fields.js';
import routes from '@/constants/server.js';

import { mapActions } from 'vuex';

import axios from '@/js/http-client.js';

export default {
  name: 'AddPartForm',
  components: {
    TextField,
    ModalPreloader,
    Alert,
    Btn,
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      console.log('PARTDATA');
      console.log(this.formData);
      axios.post(routes.parts.save, this.formData)
        .then(response => {
          console.log('RESP');
          console.log(response);
          this.success.is = true;
          this.loading = false;
          // this.getLaptopsNav();
          // setTimeout(this.clear.bind(this), 3000);
        })
        .catch(error => {
          console.log(error);
          this.error.is = true;
          this.loading = false;
          //setTimeout(this.clear.bind(this), 3000);
        });
    },
    clear() {
      this.success.is = false;
      this.error.is = false;
      this.$emit('clear-form');
      setTimeout(this.fetchData, 300);
    },
    ...mapActions('parts', [
      'fetchData',
    ]),
  },
  computed: {
    formData() {
      return {
        title: this.title.value,
        type: this.type.value,
        condition: this.condition.value,
        price: this.price.value,
        qty: this.qty.value,
        laptop: this.laptop.value,
        content: this.content.value,
      }
    },
    alert() {
      let alert;
      if (this.success.is) {
        alert = {
          type: 'success',
          icon: 'check',
          text: this.success.message,
        };
      }
      if (this.error.is) {
        alert = {
          type: 'danger',
          icon: 'times',
          text: this.error.message,
        };
      }
      return alert;
    }
  },
  data() {
    return {
      loading: false,
      success: {
        message: 'Запчасть успешно сохранена',
        is: false,
      },
      error: {
        message: 'Произошла ошибка при обращении к серверу',
        is: false,
      },
      title: {
        value: '',
        placeholder: '',
        legend: 'Введите наименование запчасти',
        label: 'Наименование',
      },
      type: {
        value: '',
        placeholder: '',
        legend: 'Выберитем тип запчасти из выпадающего списка',
        label: 'Тип',
        endpoint: `${routes.search}part-type/`
      },
      condition: {
        value: '',
        placeholder: '',
        legend: 'Выберитем состояние из выпадающего списка',
        label: 'Состояние',
        options: ['Рабочее', 'Нерабочее']
      },
      laptop: {
        value: '',
        placeholder: '',
        legend: 'Если указать ноутбук, ВСЕ кол-во данных запчастей будет установлено на него автоматом',
        label: 'Ноутбук',
        endpoint: `${routes.laptop.search}/`
      },
      price: {
        value: '',
        placeholder: '',
        legend: 'Укажите стоимость в рублях',
        label: 'Стоимость',
      },
      qty: {
        value: '',
        placeholder: '',
        legend: 'Укажите кол-во в штуках',
        label: 'Кол-во',
      },
      content: {
        value: '',
        placeholder: '',
        legend: 'Введите краткое описание запчасти',
        label: 'Описание',
      },
    }
  },
}
</script>

<style lang="css">
  .c-modal__body {
    min-height: 620px;
  }
  .c-modal__footer .c-alert {
    margin-bottom: 0;
  }
  .c-password-generator {
    width: 26px;
    margin-left: 10px;
    position: relative;
    top: -3px;
    opacity: .7;
    cursor: pointer;
    transition: .2s linear;
  }

  .c-password-generator:hover {
    opacity: 1;
  }

  .c-modal__body--part {
    height: 620px;

  }

  .c-modal__spacer {
    padding-top: 30px;
  }
  
</style>