<template>
  <div>
    <div class="c-modal__body c-modal__body--part c-modal-height">
      <ModalPreloader v-if="loading"/>
      <div class="row mb15">
        <div class="col">
          <h4 class="u-mb-medium">
            Новое целевое действие
          </h4>

          <div class="row">
            <div class="col-sm-6">
              <div class="mb15">
                <TextField
                    :label="title.label"
                    v-model="title.value"
                    :placeholder="title.placeholder"
                    :legend="title.legend"
                />
              </div>
            </div>
            <div class="col-sm-6">
              <div class="mb15">
                <TextField
                    :label="action.label"
                    v-model="action.value"
                    :placeholder="action.placeholder"
                    :legend="action.legend"
                    :endpoint="action.endpoint"
                    v-bind:hiddenValue="action.hiddenValue"
                    v-on:update:hiddenValue="action.hiddenValue = $event"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="mb15">
                <TextField
                    :label="sum.label"
                    v-model="sum.value"
                    :placeholder="sum.placeholder"
                    :legend="sum.legend"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="mb15">
                <TextField
                    :label="content.label"
                    v-model="content.value"
                    :placeholder="content.placeholder"
                    :legend="content.legend"
                    :textarea="true"
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="c-modal__footer u-justify-end">
      <Alert
          v-if="success.is || error.is"
          :type="alert.type"
          :text="alert.text"
          :icon="alert.icon"
      />
      <Btn v-else :action="handleSubmit" icon="floppy-o" :label="'Сохранить целевое действие'" :fullwidth="false"
           type="success"/>
    </div>
  </div>
</template>

<script>
import TextField from '@/components/ui/Fields/TextField.vue';
import ModalPreloader from '@/components/ui/modal-preloader.vue';
import Alert from '@/components/ui/alert.vue';
import Btn from '@/components/ui/btn.vue';

//import fields from '@/constants/fields.js';
import routes from '@/constants/server.js';

import {mapActions} from 'vuex';

import axios from '@/js/http-client.js';

export default {
  name: 'AddPayableActionForm',
  components: {
    TextField,
    ModalPreloader,
    Alert,
    Btn,
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      axios.post(routes.payableAction.save, this.formData)
          .then(response => {
            console.log(response);
            this.success.is = true;
            this.loading = false;
            // this.getLaptopsNav();
            // setTimeout(this.clear.bind(this), 3000);
          })
          .catch(error => {
            console.log(error);
            this.error.is = true;
            this.loading = false;
            //setTimeout(this.clear.bind(this), 3000);
          });
    },
    clear() {
      this.success.is = false;
      this.error.is = false;
      this.$emit('clear-form');
      setTimeout(this.fetchData, 300);
    },
    ...mapActions('payableAction', [
      'fetchData',
    ]),
  },
  computed: {
    formData() {
      return {
        title: this.title.value,
        action: this.action.hiddenValue,
        sum: this.sum.value,
        content: this.content.value,
      }
    },
    alert() {
      let alert;
      if (this.success.is) {
        alert = {
          type: 'success',
          icon: 'check',
          text: this.success.message,
        };
      }
      if (this.error.is) {
        alert = {
          type: 'danger',
          icon: 'times',
          text: this.error.message,
        };
      }
      return alert;
    }
  },
  watch: {
    action: {
      handler: function (val) {
        if (val.hiddenValue === 'sell_laptop') {
          this.sum.legend = 'Укажите сумму в процентах от сделки'
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      loading: false,
      success: {
        message: 'Целевое действие успешно сохранено',
        is: false,
      },
      error: {
        message: 'Произошла ошибка при обращении к серверу',
        is: false,
      },
      title: {
        value: '',
        placeholder: '',
        legend: 'Введите наименование целевого действия',
        label: 'Наименование',
      },
      action: {
        value: '',
        hiddenValue: '',
        placeholder: '',
        legend: 'Выберите тип действия из выпадающего списка',
        label: 'Тип',
        endpoint: `${routes.payableAction.search}`
      },
      sum: {
        value: '',
        placeholder: '',
        legend: 'Укажите сумму в рублях',
        label: 'Сумма вознаграждения',
      },
      content: {
        value: '',
        placeholder: '',
        legend: 'Введите комментарий при необходимости',
        label: 'Описание',
      },
    }
  },
}
</script>

<style lang="css">
.c-modal__body {
  min-height: 620px;
}

.c-modal__footer .c-alert {
  margin-bottom: 0;
}

.c-password-generator {
  width: 26px;
  margin-left: 10px;
  position: relative;
  top: -3px;
  opacity: .7;
  cursor: pointer;
  transition: .2s linear;
}

.c-password-generator:hover {
  opacity: 1;
}

.c-modal__body--part {
  height: 620px;
}

.c-modal-height {
  height: 520px;
}

</style>