<template>
  <div :class="'c-field'+(open ? ' select2-container--open' : '')">
    <label v-if="label" class="c-field__label" :for="fieldId">{{ label }}</label>
    <div :class="['c-field__input-wrapper', icon ? 'with-icon' : '']">
      <i v-if="icon" :class="['fa', 'fa-'+icon]"></i>
      <textarea
          v-if="textarea"
          cols="30"
          :rows="rows"
          @focus="toggleDropdown"
          @keyup="handleKeyup"
          v-bind:value="value"
          v-on:input="$emit('input', $event.target.value)"
          :id="fieldId"
          :class="['c-input'].concat( this.error ? ['c-input--danger'] : [] )"
          :placeholder="placeholder"
          ref="input"
      >
      </textarea>
      <input
          v-else
          @focus="toggleDropdown"
          @keyup="handleKeyup"
          v-bind:value="value"
          v-on:input="$emit('input', $event.target.value)"
          :id="fieldId"
          :class="['c-input'].concat( this.error ? ['c-input--danger'] : [] )"
          :type="passwordIsShown ? 'text' : type"
          :placeholder="placeholder"
          ref="input"
      >
      <input
          v-bind:value="hiddenValue"
          v-on:input="$emit('input', $event.target.value)"
          type="hidden"
          ref="hiddenInput"
      >
      <span
          v-if="type == 'password'"
          class="c-field__password-toggler"
          @click="handleTogglePasswordVisibiliy"
      >
        <i :class="['fa', passwordIsShown ? 'fa-eye-slash' : 'fa-eye']"></i>
      </span>
      <Preloader v-show="loading" size="xs"/>
    </div>
    <span class="c-field__message">
        <i v-if="legend" class="fa fa-info-circle"></i>{{ legend }}
    </span>
    <small v-if="error" class="c-field__message u-color-danger">
      <i class="fa fa-times-circle"></i>{{ error }}
    </small>
    <span v-if="options" v-show="open" class="mtm35 select2-dropdown select2-dropdown--below">
      <span class="select2-results">
        <ul class="select2-results__options">
          <li
              v-for="(option, key) in options"
              :key="key"
              class="select2-results__option"
              @click="handleOptionClick(option, key)"
          >
            <i
                v-if="optionsAsIcons"
                :class="['fa', option]"
            ></i>
            {{ option }}
          </li>
        </ul>
      </span>
    </span>
    <span v-if="dynamicOptions" v-show="open" class="mtm35 select2-dropdown select2-dropdown--below">
      <span class="select2-results">
        <ul class="select2-results__options">
          <li
              v-for="(option, key) in dynamicOptions"
              :key="key"
              class="select2-results__option"
              @click="handleOptionClick(option, key)"
          >
            <i
                v-if="optionsAsIcons"
                :class="['fa', option]"
            ></i>
             {{ option }}
          </li>
        </ul>
      </span>
    </span>
  </div>
</template>

<script>
import Preloader from '@/components/ui/preloader.vue';
import {uid} from '@/js/helpers.js';
import axios from '@/js/http-client.js';
import validator from '@/js/validator.js';
import {dropdownMixin} from '../../mixins/DropdownMixin.js';

export default {
  name: 'TextField',
  computed: {
    fieldId() {
      return uid();
    }
  },
  components: {
    Preloader
  },
  mixins: [dropdownMixin],
  data() {
    return {
      loading: false,
      open: false,
      dynamicOptions: false,
      passwordIsShown: false,
      error: false,
    }
  },
  methods: {
    toggleDropdown() {
      this.open = !this.open

      if (this.open === true && this.endpoint && this.value.length === 0) {
        this.fetchOptions();
      }
    },
    handleTogglePasswordVisibiliy() {
      this.passwordIsShown = !this.passwordIsShown;
    },
    handleBlur() {
      this.open = false;
    },
    array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr; // for testing
    },
    handleKeyup(event) {
      if (this.dynamicOptions === false && this.$props.options.length > 0) {
        let searchText = event.target.value.toString().toLowerCase();
        let opts = this.$props.options;
        if (searchText.length >= 2) {
          let self = this;
          opts.forEach(function (item, i, arr) {
            let optsText = item.toString().toLowerCase();
            if (optsText.includes(searchText)) {
              self.array_move(arr, i, 0);
            }
          });
        }
      }
      if (this.endpoint) {
        this.fetchOptions();
      }
      if (this.validate) {
        this.handleValidation();
      }
    },
    handleOptionClick(option, key) {
      if (this.multiple && this.$refs.input.value.length > 0) {
        let splitedValue = this.$refs.input.value.split(',');
        let splitedHiddenValue = this.$refs.hiddenInput.value.split(',');

        let index = splitedValue.indexOf(option);

        if (index == -1) {
          splitedValue.push(option);
          splitedHiddenValue.push(key);
        } else {
          splitedValue.splice(index, 1);
          splitedHiddenValue.splice(index, 1);
        }

        this.$refs.input.value = splitedValue.join(',');
        this.$emit('update:hiddenValue', splitedHiddenValue.join(','));

      } else {
        this.$refs.input.value = option;
        this.$emit('update:hiddenValue', key);//set hiddenValue param as key to use later if we need
      }
      //console.log(this.$refs.input.value);
      this.triggerInput();
      this.handleBlur();
    }
    ,
    handleValidation() {
      let result = validator.validateField({
        value: this.value,
        validate: this.validate,
      });

      if (result !== true) {
        this.error = result.error;
        this.validate.valid = false;
      } else {
        this.error = false;
        this.validate.valid = true;
      }
    }
    ,
    triggerInput() {
      let event = new Event('input', {
        bubbles: true,
        cancelable: true,
      });
      this.$refs.input.dispatchEvent(event);
    }
    ,
    fetchOptions() {
      this.loading = true;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        axios.post(this.endpoint, {s: this.value})
            .then((response) => {
              if (response.data) {
                this.dynamicOptions = response.data;
              } else {
                this.dynamicOptions = false;
              }
              this.loading = false;
            })
            .catch((error) => {
              console.log(error);
              this.loading = false;
            });
      }, 350);

    }
  },
  props: {
    value: {
      type: [String, Number],
      default:
          null
    }
    ,
    hiddenValue: {
      type: [String, Number, Boolean],
      default:
          null,
    }
    ,
    placeholder: {
      type: String,
      default:
          null
    }
    ,
    label: {
      type: String,
      default:
          null
    }
    ,
    legend: {
      type: String,
      default:
          null
    }
    ,
    options: {
      type: [Array, Object, Boolean],
      default:
          false
    }
    ,
    endpoint: {
      type: [String, Boolean],
      default:
          false
    }
    ,
    optionsAsIcons: {
      type: Boolean,
      default:
          false
    }
    ,
    textarea: {
      type: Boolean,
      default:
          false,
    }
    ,
    rows: {
      type: Number,
      default:
          1,
    }
    ,
    icon: {
      type: [Boolean, String],
      default:
          false,
    }
    ,
    type: {
      type: [String],
      default:
          "text",
    }
    ,
    validate: {
      type: [Object, Boolean],
      default:
          false,
    }
    ,
    multiple: {
      type: Boolean,
      default:
          false,
    }
  }
}
</script>

<style lang="css">
.c-field__input-wrapper {
  position: relative;
}

.c-field__input-wrapper .preloader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: initial !important;
  right: 10px !important;
  margin: auto;
}

.select2-results__options {
  max-height: 210px;
  overflow: scroll;
}

.select2-results__option .fa {
  margin-right: 5px;
}

.with-icon i {
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 12px;
  font-size: 12px;
  opacity: .2;
}

.with-icon input {
  padding-left: 30px;
}

.c-field__password-toggler {
  position: absolute;
  top: 0;
  right: 3px;
  font-size: 16px;
  padding: 6px;
  cursor: pointer;
}
</style>