<template>
  <div>
    <div class="c-modal__body c-modal__body--work">
      <ModalPreloader v-if="loading"/>
      <div class="row mb15">
        <div class="col">
          <h4 class="u-mb-medium">
            Добавление запчастей
          </h4>

          <div class="row">
            <div class="col" style="margin-left:-15px">
              <RepeaterField :rows="parts" addLabel="Добавить запчасть"/>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="mb15">
                <TextField
                    :label="description.label"
                    v-model="description.value"
                    :placeholder="description.placeholder"
                    :legend="description.legend"
                    :textarea="true"
                    :rows="8"
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="c-modal__footer u-justify-end">
      <Alert
          v-if="success.is || error.is"
          :type="alert.type"
          :text="alert.text"
          :icon="alert.icon"
      />
      <Btn v-else :action="handleSubmit" icon="check" :label="'Установить запчасти'" :fullwidth="false" type="success"/>
    </div>
  </div>
</template>

<script>
import TextField from '@/components/ui/Fields/TextField.vue';
import RepeaterField from '@/components/ui/Fields/repeater-field.vue';
import ModalPreloader from '@/components/ui/modal-preloader.vue';
import Alert from '@/components/ui/alert.vue';
import Btn from '@/components/ui/btn.vue';

//import fields from '@/constants/fields.js';
import routes from '@/constants/server.js';
import {mapActions, mapState} from 'vuex';
import axios from '@/js/http-client.js';

export default {
  name: 'InstallPartsForm',
  components: {
    TextField,
    RepeaterField,
    ModalPreloader,
    Alert,
    Btn,
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      console.log('ADD PART TO LAPTOP');
      console.log(this.formData);

      axios.post(routes.laptop.install, this.formData)
          .then(response => {
            console.log('RESP');
            console.log(response);
            this.success.is = true;
            this.loading = false;
            setTimeout(this.clear.bind(this), 2000);
          })
          .catch(error => {
            console.log(error);
            this.error.is = true;
            this.loading = false;
            setTimeout(this.clear.bind(this), 2000);
          });
    },
    clear() {
      this.success.is = false;
      this.error.is = false;
      this.$emit('clear-form');
      setTimeout(() => {
        this.fetchData(this.laptop.id);
      }, 300);
    },
    ...mapActions('laptop', [
      'fetchData',
    ]),
  },
  computed: {
    ...mapState({
      laptop: state => state.laptop.laptop,
    }),
    formData() {
      const laptop = this.laptop.id;
      let parts = {};
      this.parts.map((part) => {
        parts[part[0].hiddenValue] = parseInt(part[1].value);
      });

      return {
        laptop,
        parts,
        comment: this.description.value,
      }
    },
    alert() {
      let alert;
      if (this.success.is) {
        alert = {
          type: 'success',
          icon: 'check',
          text: this.success.message,
        };
      }
      if (this.error.is) {
        alert = {
          type: 'danger',
          icon: 'times',
          text: this.error.message,
        };
      }
      return alert;
    }
  },
  data() {
    return {
      loading: false,
      success: {
        message: 'Запчасть установлена',
        is: false,
      },
      error: {
        message: 'Произошла ошибка при обращении к серверу',
        is: false,
      },
      parts: [
        [
          {
            label: 'Запчасть',
            value: '',
            hiddenValue: 1,
            placeholder: '',
            legend: 'Выберите запчасть',
            endpoint: routes.parts.search,
          },
          {
            label: 'Количество',
            value: '',
            placeholder: '1',
            legend: 'Укажите количество'
          },
        ]
      ],
      description: {
        value: '',
        placeholder: '',
        legend: 'При необходимости, добавьте комментарий',
        label: 'Комментарий',
      },

    }
  },
}
</script>

<style lang="css">
.c-modal__body {
  min-height: 500px;
}

.c-modal__footer .c-alert {
  margin-bottom: 0;
}

.c-password-generator {
  width: 26px;
  margin-left: 10px;
  position: relative;
  top: -3px;
  opacity: .7;
  cursor: pointer;
  transition: .2s linear;
}

.c-password-generator:hover {
  opacity: 1;
}

.c-modal__body--work {
  height: 420px;
  overflow: scroll;
}

</style>