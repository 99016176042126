import axios from '@/js/http-client.js';
import routes from '@/constants/server.js';

import router from '@/router/index.js';

import { clearAuth } from '@/js/helpers.js';

import AddLaptopModal from '@/components/ui/Modals/AddLaptopModal.vue';

export default {
  namespaced: true,
  state: {
    action: AddLaptopModal,
    loading: true,
    error: false,
    table: {
      total: 0,
      items: [],
      perPage: 10,
      current: 1,
      pages: 1
    }
  },
  getters: {
    action: state => state.action,
    loading: state => state.loading,
    error: state => state.error,
    table: state => state.table,
  },
  mutations: {
    startLoading: state => state.loading = true,
    stopLoading: state => state.loading = false,
    setTable: (state, table) => state.table = table,
    setError: state => state.error = true,
    unsetError: state => state.error = false,
  },
  actions: {
    fetchData(context, view) {
      context.commit('startLoading');
      const query = `?view=${view}&perPage=${context.state.table.perPage}&current=${context.state.table.current}`;
      axios.get(routes.laptop.list+query)
        .then((response) => {
          if (response.data.statusCode == 403) {  
            clearAuth();

            router.push('/login');
          } else {
            context.commit('setTable', response.data);
            context.commit('stopLoading');
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = true;
          router.push('/login');
          context.commit('stopLoading');
         
        });
    },
    fetchFilteredData(context, data) {
      context.commit('startLoading');
      const query = {
        view: data.view,
        perPage: context.state.table.perPage,
        current: context.state.table.current,
        filters: data.filters,
      }
      
      axios.post(routes.laptop.filter, query)
      .then((response) => {
        context.commit('setTable', response.data);
        context.commit('stopLoading');
      })
      .catch((error) => {
        console.log(error);
        this.error = true;
        //data.router.push('/login');
        context.commit('stopLoading');
      });

    }
  }
}