<template>
  <td class="c-table__cell">{{ channel }} / {{ price }} ₽
    <small class="u-block u-text-mute"><i class="fa fa-user-o mr5"></i>{{ buyer }}</small>
  </td>
</template>
<script>
export default {
  name: 'Buy',
  computed: {
    channel() {
      return this.data.channel;
    },
    price() {
      return this.data.price;
    },
    buyer() {
      return this.data.buyer;
    },
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>