<template>
  <td class="c-table__cell">
    <span v-if="resolution">
      <span v-if="diagonal">{{ diagonal }}" /</span> {{ resolution }}
      <small class="u-block u-text-mute">{{ type }}, {{ matrix }}</small>
    </span>
    <span v-else>—</span>
  </td>
</template>
<script>
export default {
  name: 'Screen',
  computed: {
    diagonal() {
      return this.data.diagonal;
    },
    resolution() {
      return this.data.resolution;
    },
    type() {
      return this.data.type;
    },
    matrix() {
      return this.data.matrix;
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>