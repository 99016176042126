<template>
  <div>
    <Sidebar/>
    <main class="o-page__content">
      <Topbar/>
      <div class="container">
        <Breadcrumbs :items="this.breadcrumbs"/>
        <Preloader v-if="loading"/>
        <div v-else class="row">

          <div class="col-xl-9">
            <!-- START: COMMON INFO -->
            <div class="c-card u-p-medium u-mb-medium">
              <div class="row">
                <div class="col">
                  <h3 class="u-h3">{{ brand }} {{ model }} {{ year }}</h3>
                  <span class="u-text-mute u-text-small">
                          <i class="fa fa-barcode"></i> {{ laptop.sku }}
                        </span>
                </div>
                <div class="col u-text-right">
                  <LaptopStatus :data="laptop.statusObject"/>
                </div>
              </div>

              <div class="u-flex u-mt-medium">
                <AddWorkModal v-if="(isWorker() || isAdmin()) && laptop.statusObject.title != 'Продан'"/>
                <SellActionModal v-if="(isManager() || isAdmin()) && laptop.statusObject.title != 'Продан'"/>
                <SetSellPriceActionModal v-if="(isManager() || isAdmin()) && laptop.statusObject.title != 'Продан'"/>
                <ChangeStatusModal/>
              </div>

              <table class="c-table u-text-center u-pv-small u-mt-medium u-border-right-zero u-border-left-zero">
                <thead>
                <tr>
                  <th class="u-pt-small">

                    <LaptopState :state="+laptop.state"/>

                  </th>
                  <th class="u-pt-small u-color-primary">
                    <i v-if="laptop.charge" class="fa fa-check u-color-success"></i>
                    <i v-else class="fa fa-times u-color-danger"></i>
                  </th>
                  <th class="u-pt-small u-color-primary">
                    <i v-if="laptop.box" class="fa fa-check u-color-success"></i>
                    <i v-else class="fa fa-times u-color-danger"></i>
                  </th>
                  <th class="u-pt-small u-color-primary">
                    <i v-if="laptop.insurance" class="fa fa-check u-color-success"></i>
                    <i v-else class="fa fa-times u-color-danger"></i>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="u-text-mute u-text-xsmall u-pb-small u-text-uppercase">Состояние</td>
                  <td class="u-text-mute u-text-xsmall u-pb-small u-text-uppercase">Зарядка</td>
                  <td class="u-text-mute u-text-xsmall u-pb-small u-text-uppercase">Коробка</td>
                  <td class="u-text-mute u-text-xsmall u-pb-small u-text-uppercase">Гарантия</td>
                </tr>
                </tbody>
              </table>

              <div class="c-feed has-icons u-mt-medium">
                <!-- START: Покупка -->
                <div v-if="laptop.buyChannel" class="c-feed__item has-icon">
                  <i class="c-feed__item-icon u-bg-info fa fa-dollar"></i>
                  <p>Куплен через {{ laptop.buyChannel.name }}</p>
                  <span class="c-feed__meta">
                            <i class="fa fa-user-o" style="opacity: .5"></i> {{
                      laptop.buyer.display_name ? laptop.buyer.display_name : 'Покупатель не указан'
                    }},
                            {{ buyPrice }}
                          </span>
                </div>

                <div v-else class="c-feed__item has-icon">
                  <i class="c-feed__item-icon u-bg-primary fa fa-dollar"></i>
                  <p>Куплен через {{ laptop.buyChannel.name }}</p>
                  <span class="c-feed__meta">
                            <i class="fa fa-user-o" style="opacity: .5"></i> {{
                      laptop.buyer.display_name ? laptop.buyer.display_name : 'Покупатель не указан'
                    }},
                            {{ buyPrice }}
                          </span>
                </div>
                <!-- END: Покупка -->
                <!-- START: Ремонт -->
                <div v-if="laptop.worker" class="c-feed__item has-icon">
                  <i class="c-feed__item-icon u-bg-warning fa fa-wrench"></i>
                  <p>Ремонт</p>
                  <span class="c-feed__meta">
                            <i class="fa fa-user-o" style="opacity: .5"></i> {{ laptop.worker.display_name }},
                            {{ workPrice }}
                          </span>
                </div>

                <div v-else class="c-feed__item has-icon">
                  <i class="c-feed__item-icon u-bg-primary fa fa-wrench"></i>
                  <p>Ремонт</p>
                  <span class="c-feed__meta">
                            Не производился
                          </span>
                </div>
                <!-- END: Ремонт -->
                <!-- START: Запчасти -->
                <div v-if="laptop.parts" class="c-feed__item has-icon">
                  <i class="c-feed__item-icon u-bg-warning fa fa-cogs"></i>
                  <p>Запчасти</p>
                  <span class="c-feed__meta">
                            {{ partsPrice }}
                          </span>
                </div>

                <div v-else class="c-feed__item has-icon">
                  <i class="c-feed__item-icon u-bg-primary fa fa-cogs"></i>
                  <p>Запчасти</p>
                  <span class="c-feed__meta">
                            Не установлены
                          </span>
                </div>
                <!-- END: Запчасти -->
                <!-- START: Продажа -->
                <div v-if="laptop.prices.sold > 0" class="c-feed__item has-icon">
                  <i class="c-feed__item-icon u-bg-success fa fa-dollar"></i>
                  <p>Продажа</p>
                  <p class="c-feed__meta">
                    <i class="fa fa-user-o" style="opacity: .5"></i> {{ laptop.seller.display_name }},
                    {{ soldPrice }} ({{ salePrice }})
                  </p>
                </div>

                <div v-else class="c-feed__item has-icon">
                  <i class="c-feed__item-icon u-bg-primary fa fa-dollar"></i>
                  <p>Продажа не произведена</p>
                  <p class="c-feed__meta">
                    {{ laptop.prices.sale > 0 ? salePrice : 'Цена не установлена' }}
                  </p>
                </div>
                <!-- END: Продажа -->
              </div>

            </div>
            <!-- END: COMMON INFO -->
            <!-- START: TECHNICAL INFO -->
            <div class="c-card u-p-medium u-text-small u-mb-small">
              <div class="row">
                <div class="col">
                  <h5>Техническая информация</h5>

                  <dl class="u-flex u-pv-small u-border-bottom">
                    <dt class="u-width-50 u-text-mute">Процессор</dt>
                    <dd>
                      {{ cpu }}
                      <div class="u-text-mute" v-if="cpu">
                        {{ laptop.cpu.cores }} cores
                        <span v-if="laptop.cpu.min_freq">
                              {{ laptop.cpu.min_freq }}
                              <span v-if="laptop.cpu.max_freq">-
                                {{ laptop.cpu.max_freq }}
                              </span>
                              MHz
                            </span>
                      </div>
                      <div class="u-text-mute" v-else>
                        <i class="fa fa-times" style="opacity: .7"></i>
                      </div>
                    </dd>
                  </dl>
                  <dl class="u-flex u-pv-small u-border-bottom">
                    <dt class="u-width-50 u-text-mute">Видеокарта</dt>
                    <dd>
                      {{ gpu }}
                      <div v-if="gpu" class="u-text-mute">
                        {{ laptop.gpu.memory }}GB
                      </div>
                      <div class="u-text-mute" v-else>
                        <i class="fa fa-times" style="opacity: .7"></i>
                      </div>
                    </dd>
                  </dl>
                  <dl class="u-flex u-pv-small u-border-bottom">
                    <dt class="u-width-50 u-text-mute">Оперативная память</dt>
                    <dd>
                      {{ ram }}
                      <div v-if="ram" class="u-text-mute">
                        {{ laptop.ram.type.name }}
                      </div>
                      <div class="u-text-mute" v-else>
                        <i class="fa fa-times" style="opacity: .7"></i>
                      </div>
                    </dd>
                  </dl>
                  <dl class="u-flex u-pv-small u-border-bottom">
                    <dt class="u-width-50 u-text-mute">Экран</dt>
                    <dd>{{ screen }}
                      <div class="u-text-mute" v-if="screen">
                        {{ laptop.screen.type.name }},
                        {{ laptop.screen.matrix.name }}
                      </div>
                      <div class="u-text-mute" v-else>
                        <i class="fa fa-times" style="opacity: .7"></i>
                      </div>
                    </dd>
                  </dl>
                  <dl class="u-flex u-pv-small u-border-bottom">
                    <dt class="u-width-50 u-text-mute">Жесткие диски</dt>
                    <dd v-if="laptop.hards">
                          <span v-for="(hard, i) in laptop.hards" :key="i">
                            <span v-if="hard.turns" class="c-tooltip c-tooltip--top"
                                  :aria-label="hard.turns ? 'Обороты: '+hard.turns : ''"><i class="fa fa-hdd-o"
                                                                                            style="color: black;"></i> {{
                                hard.tip.toUpperCase()
                              }}, {{ hard.volume }} GB, {{ hard.smart }}%*</span>
                            <span v-else-if="hard.volume"><i class="fa fa-hdd-o" style="color: black;"></i> {{
                                hard.tip.toUpperCase()
                              }}, {{
                                hard.volume
                              }} GB, {{ hard.smart }}%</span>
                            <span v-else>
                              <i class="fa fa-times" style="opacity: .7"></i>
                            </span>
                            <br>
                          </span>
                    </dd>
                    <dd v-else><i class="fa fa-times" style="opacity: .7"></i></dd>
                  </dl>
                  <dl class="u-flex u-pv-small u-border-bottom">
                    <dt class="u-width-50 u-text-mute">Операционная система</dt>
                    <dd><i :class="['fa', `fa-${osLogo }`]" style="opacity: .7"></i> <span v-if="laptop.os">{{
                        laptop.os.name
                      }}</span></dd>
                  </dl>
                  <dl class="u-flex u-pv-small u-border-bottom">
                    <dt class="u-width-50 u-text-mute">АКБ</dt>
                    <dd v-if="laptop.akb">{{ laptop.akb }}%</dd>
                    <dd v-else><i class="fa fa-times" style="opacity: .7"></i></dd>
                  </dl>
                </div>
              </div>
            </div>
            <!-- END: TECHNICAL INFO -->
            <!-- START: PARTS INFO -->
            <div class="c-card u-p-medium u-text-small u-mb-small">
              <div class="row">
                <div class="col">
                  <h5>Установленные запчасти</h5>
                  <!-- START: PARTS TABLE -->
                  <table v-if="laptop.parts.length > 0" class="c-table u-mt-medium">
                    <thead class="c-table__head c-table__head--slim">
                    <tr class="c-table__row">
                      <th class="c-table__cell c-table__cell--head">Запчасть</th>
                      <th class="c-table__cell c-table__cell--head">Описание</th>
                      <th class="c-table__cell c-table__cell--head">Стоимость</th>
                      <th class="c-table__cell c-table__cell--head">Установил</th>
                      <th class="c-table__cell c-table__cell--head" v-if="isWorker() || isAdmin()">Опции</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, key) in laptop.parts" :key="key" class="c-table__row">
                      <td class="c-table__cell">
                        {{ item.part.title }}
                        <span class="u-text-mute" v-if="item.qty > 1"> (x{{ item.qty }})</span>
                        <div class="u-text-mute">{{ item.part.type.name }}</div>
                      </td>
                      <td class="c-table__cell">
                        <PartDescription :content="item.part.content"/>
                      </td>
                      <td class="c-table__cell">{{ moneyFormat(item.part.price) }}</td>
                      <td class="c-table__cell" v-if="item.user.display_name">{{ item.user.display_name }}</td>
                      <td class="c-table__cell" v-else>&mdash;</td>
                      <td class="c-table__cell" v-if="isWorker() || isAdmin()">
                        <DropdownMenu :links="getLinks(key)" label="Действия"/>
                      </td>
                    </tr>
                    </tbody>


                  </table>
                  <div class="u-opacity-medium" v-else>
                    Нет установленных запчастей
                  </div>
                  <!-- END: PARTS TABLE -->
                  <!-- START: PARTS ACTIONS -->
                  <div class="u-flex u-mt-medium" v-if="isWorker() || isAdmin()">
                    <InstallPartsModal/>
                  </div>
                  <!-- END: PARTS ACTIONS -->
                </div>
              </div>
            </div>
            <!-- END: PARTS INFO -->
          </div>


          <div class="col-xl-3">
            <LaptopTabs :laptop="laptop"/>
          </div>


        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Topbar from '@/components/layout/Topbar.vue';
import Sidebar from '@/components/layout/Sidebar.vue';
import Breadcrumbs from '@/components/ui/breadcrumbs.vue';
import Preloader from '@/components/ui/preloader.vue';
import LaptopTabs from '@/components/laptop/LaptopTabs.vue';
import LaptopStatus from '@/components/laptop/LaptopStatus.vue';
import LaptopState from '@/components/laptop/LaptopState.vue';
import AddWorkModal from '@/components/ui/Modals/AddWorkModal.vue';
import SellActionModal from '@/components/ui/Modals/SellActionModal.vue';
import SetSellPriceActionModal from '@/components/ui/Modals/SetSellPriceActionModal.vue';
import ChangeStatusModal from '@/components/ui/Modals/ChangeStatusModal.vue';
import InstallPartsModal from '@/components/ui/Modals/InstallPartsModal.vue';
import DropdownMenu from '@/components/ui/dropdown-menu.vue';
import PartDescription from '@/components/parts/PartDescription.vue';

import {mapState, mapActions} from 'vuex';
import routes from '@/constants/server.js';
import axios from '@/js/http-client.js';
import {moneyFormat} from '@/js/helpers.js';
import {isWorker, isAdmin, isManager} from '@/js/helpers.js';

export default {
  name: 'SingleLaptop',
  components: {
    Topbar,
    Sidebar,
    Breadcrumbs,
    Preloader,
    LaptopTabs,
    LaptopStatus,
    LaptopState,
    AddWorkModal,
    SellActionModal,
    SetSellPriceActionModal,
    ChangeStatusModal,
    InstallPartsModal,
    DropdownMenu,
    PartDescription,
  },
  mounted() {
    this.fetchData(this.id);
  },
  watch: {
    $route() {
      this.fetchData(this.id);
    }
  },
  computed: {
    ...mapState({
      loading: state => state.laptop.loading,
      laptop: state => state.laptop.laptop,
      error: state => state.laptop.error,
    }),
    id() {
      const {id} = this.$route.params;
      return id;
    },
    breadcrumbs() {
      return [
        {
          path: '/#/laptops/171',
          title: 'Ноутбуки'
        },
        {
          title: this.laptop.title,
        }
      ];
    },
    brand() {
      return this.laptop.brand.name;
    },
    model() {
      return this.laptop.model;
    },
    year() {
      return this.laptop.year;
    },
    buyPrice() {
      return moneyFormat(this.laptop.prices.buy);
    },
    parts() {
      return this.laptop.parts;
    },
    partsPrice() {
      return moneyFormat(this.laptop.prices.parts);
    },
    workPrice() {
      return moneyFormat(this.laptop.prices.work);
    },
    soldPrice() {
      return moneyFormat(this.laptop.prices.sold);
    },
    salePrice() {
      return moneyFormat(this.laptop.prices.sale);
    },
    cpu() {
      return this.laptop.cpu === null ? "" : this.laptop.cpu.model.name;
    },
    gpu() {
      return this.laptop.gpu === null ? "" : this.laptop.gpu.model.name;
    },
    ram() {
      return this.laptop.ram === null ? "" : this.laptop.ram.memory + 'GB';
    },
    screen() {
      return this.laptop.screen === null ? "" : this.laptop.screen.diagonal.name + '" / ' + this.laptop.screen.resolution.name;
    },
    osLogo() {
      let logo = '';
      if (this.laptop.os) {
        if (this.laptop.os.slug.indexOf('win') > -1) {
          logo = 'windows';
        }
        if (this.laptop.os.slug.indexOf('mac') > -1) {
          logo = 'apple';
        }
      } else {
        logo = 'times';
      }
      return logo;
    }
  },
  methods: {
    isWorker,
    isAdmin,
    isManager,
    handleRemovePart(id) {
      let result = confirm("Вы уверены, что хотите снять деталь с ноутбука?");

      if (result) {
        let data = {
          'laptop': this.id,
          'part': this.parts[id],
        };

        axios.post(routes.laptop.uninstall, data)
            .then(response => {
              console.log('RESP');
              console.log(response);
              this.fetchData(this.id)
            })
            .catch(error => {
              console.log(error);
              this.error.is = true;
            });
      }
    },
    getLinks(id) {
      //id -= 1;
      return [
        {
          label: "Снять запчасть",
          href: "/#/laptop/" + this.id,
          action: () => {
            this.handleRemovePart(id)
          },
        },
      ];
    },
    ...mapActions('laptop', [
      'fetchData',
    ]),
    moneyFormat: moneyFormat,
  },
}
</script>