import { getUser } from '@/js/helpers.js';

export default {
  namespaced: true,
  state: getUser(),
  getters: {
    token: state => state.token,
    id: state => state.id,
    email: state => state.email,
    nicename: state => state.nicename,
    firstName: state => state.firstName,
    lastName: state => state.lastName,
    displayName: state => state.displayName,
    avatar: state => state.avatar,
    role: state => state.role,
  },
  mutations: {
    setToken: (state, token) => {
      state.token = token;
    },
    setId: (state, id) => {
      state.id = id;
    },
    setEmail: (state, email) => {
      state.email = email;
    },
    setNicename: (state, nicename) => {
      state.nicename = nicename;
    },
    setFirstName: (state, firstName) => {
      state.firstName = firstName;
    },
    setLastName: (state, lastName) => {
      state.lastName = lastName;
    },
    setDisplayName: (state, displayName) => {
      state.displayName = displayName;
    },
    setAvatar: (state, avatar) => {
      state.avatar = avatar;
    },
    setRole: (state, role) => {
      state.role = role;
    },
  },
  actions: {
    setUser(context, user) {
      context.commit('setToken', user.token);
      context.commit('setId', user.id);
      context.commit('setEmail', user.email);
      context.commit('setNicename', user.nicename);
      context.commit('setFirstName', user.firstName);
      context.commit('setLastName', user.lastName);
      context.commit('setDisplayName', user.displayName);
      context.commit('setAvatar', user.avatar);
      context.commit('setRole', user.role);
    }
  },
}