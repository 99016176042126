<template>
  <td class="c-table__cell">{{ model }}
      <small class="u-block u-text-mute">
        <i class="fa fa-laptop mr5"></i>{{ brand }}
        <span v-if="sku">, <i class="fa fa-barcode mr5"></i>{{ sku }}</span>
      </small>
  </td>
</template>
<script>
export default {
  name: 'Model',
  computed: {
    model() {
      return this.data.model;
    },
    sku() {
      return this.data.sku;
    },
    brand() {
      return this.data.brand;
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>