<template>
  <span :class="['preloader', 'preloader--'+size].concat(inversion ? ['preloader--inversion'] : [])"><span></span><span></span><span></span><span></span></span>
</template>

<script>
export default {
  name: "Preloader",
  props: {
    size: {
      type: String,
      default: 'md',
      validator: (value) => {
        return [
          'xs',
          'sm',
          'md',
          'lg'
        ].indexOf(value) !== -1;
      }
    },
    inversion: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style lang="css">
  .preloader {
    display: inline-block;
    position: relative;
  }

  .preloader--xs {
    width: 20px;
    height: 20px;
  }

  .preloader--xs span {
    width: 15px;
    height: 15px;
    margin: 2px;
    border: 2px solid #1a91eb;
  }

  .preloader--sm {
    width: 50px;
    height: 50px;
  }

  .preloader--sm span {
    width: 34px;
    height: 34px;
    margin: 5px;
    border: 5px solid #1a91eb;
  }

  .preloader--md {
    width: 80px;
    height: 80px;
  }

  .preloader--md span {
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #1a91eb;
  }

  .preloader span {
    box-sizing: border-box;
    display: block;
    position: absolute;
    border-radius: 50%;
    animation: preloader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #1a91eb transparent transparent transparent;
  }
  .preloader span:nth-child(1) {
    animation-delay: -0.45s;
  }
  .preloader span:nth-child(2) {
    animation-delay: -0.3s;
  }
  .preloader span:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes preloader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .preloader--inversion span {
    border-color: white transparent transparent transparent;
  }
</style>