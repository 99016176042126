export default [
  'Наименование',
  'Добавлен',
  'Модель',
  'Статус',
  'Видеокарта',
  'Экран',
  'Процессор',
  'Оперативная память',
  'Операционная система',
  'Жесткие диски',
  'АКБ',
  'Покупка',
  'Ремонт',
  'Продажа',
  'Комментарий',
];