<template>
  <div class="c-dropdown dropdown" ref="parent">
      <a @click.prevent="toggleDropdown" class="c-avatar c-avatar--xsmall has-dropdown dropdown-toggle"
         href="#" id="dropdwonMenuAvatar" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <img class="c-avatar__img" :src="image" alt="">
      </a>
      <Dropdown :links="links" :show="open" />
  </div>
</template>

<script>

import Dropdown from "@/components/ui/dropdown.vue";
import { dropdownMixin } from '../mixins/DropdownMixin';

export default {
  name: "DropdownAvatar",
  components: {
    Dropdown,
  },
  mixins: [ dropdownMixin ],
  data() {
    return {
      open: false,
    };
  },
  methods: {
    toggleDropdown () {
      this.open = !this.open
    },
  },
  props: {
    image: {
      type: String,
      default: ""
    },
    links: {
      type: Array,
      default: () => {return []},
    },
  }
}
</script>