<template>
  <div>
    <Btn label="Добавить представление" type="success" icon="plus" :action="openModal" :fullwidth="false"/>
    <Modal :open="opened" :title="title" :handleClose="closeModal" size="huge">
      <AddViewForm @clear-form="closeModal"/>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/ui/modal.vue';
import Btn from '@/components/ui/btn.vue';
import AddViewForm from '@/components/forms/AddViewForm.vue';

import {mapMutations, mapGetters} from 'vuex';

export default {
  name: 'AddViewModal',
  components: {
    AddViewForm,
    Modal,
    Btn,
  },
  data() {
    return {
      title: "Добавить представление"
    };
  },
  computed: {
    ...mapGetters('views', [
      'opened'
    ])
  },
  methods: {
    ...mapMutations('views', [
      'openModal',
      'closeModal'
    ]),
  }
}
</script>