<template>
  <h4 class="c-sidebar__title">{{ title }}</h4>
</template>

<script>
export default {
  name: 'SidebarTitle',
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>