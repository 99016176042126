<template>
  <div>
    <div class="c-modal__body c-modal__body--status">
      <ModalPreloader v-if="loading" />
      <div class="row mb15">
        <div class="col">
          <h4 class="u-mb-medium">
            Смена статуса
          </h4>
          <div class="row">
            <div class="col-sm-6">
              <p class="u-text-mute u-text-small u-mb-xsmall">Текущий статус</p>
              <LaptopStatus :data="laptop.statusObject" />
            </div>
            <div class="col-sm-6">
              <div class="mb15">
                <TextField
                  :label="status.label"
                  v-model="status.value"
                  :placeholder="status.placeholder"
                  :legend="status.legend"
                  :options="status.options"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="mb15">
                <TextField
                  :label="comment.label"
                  v-model="comment.value"
                  :placeholder="comment.placeholder"
                  :legend="comment.legend"
                  :options="comment.options"
                  :textarea="true"
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="c-modal__footer u-justify-end">
      <Alert
        v-if="success.is || error.is"
        :type="alert.type"
        :text="alert.text"
        :icon="alert.icon"
      />
      <Btn v-else :action="handleSubmit" icon="refresh" :label="'Сменить статус'" :fullwidth="false" type="success" />
    </div>
  </div>
</template>

<script>
import TextField from '@/components/ui/Fields/TextField.vue';
import ModalPreloader from '@/components/ui/modal-preloader.vue';
import Alert from '@/components/ui/alert.vue';
import Btn from '@/components/ui/btn.vue';

import LaptopStatus from '@/components/laptop/LaptopStatus.vue';
//import fields from '@/constants/fields.js';
import routes from '@/constants/server.js';

import { mapActions, mapState } from 'vuex';

import axios from '@/js/http-client.js';

export default {
  name: 'ChangeStatusForm',
  components: {
    TextField,
    ModalPreloader,
    Alert,
    Btn,
    LaptopStatus,
  },
  mounted() {
    this.loading = true;
    axios.get(routes.user.statuses)
        .then(response => {
          console.log(response);
          let i = response.data.indexOf(this.currentStatus);
          if (i > -1) {
            response.data.splice(i, 1);
          }
          this.status.options = response.data;
          this.loading = false;
          // this.getLaptopsNav();
          // setTimeout(this.clear.bind(this), 3000);
        })
        .catch(error => {
          console.log(error);
          this.error.is = true;
          this.loading = false;
          //setTimeout(this.clear.bind(this), 3000);
        });
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      axios.post(routes.laptop.status, this.formData)
        .then(response => {
          console.log(response);
          this.success.is = true;
          this.loading = false;
          // this.getLaptopsNav();
          setTimeout(this.clear.bind(this), 2000);
        })
        .catch(error => {
          console.log(error);
          this.error.is = true;
          this.loading = false;
          setTimeout(this.clear.bind(this), 2000);
        });
    },
    clear() {
      this.success.is = false;
      this.error.is = false;
      this.$emit('clear-form');
      setTimeout(() => {
        this.fetchData(this.laptop.id);
      }, 300);
    },
    ...mapActions('laptop', [
      'fetchData',
    ]),
  },
  computed: {
    ...mapState({
      laptop: state => state.laptop.laptop,
    }),
    formData() {
      return {
        old: this.currentStatus,
        new: this.status.value,
        comment: this.comment.value,
        laptop: this.laptop.id,
      }
    },
    currentStatus() {
      return this.laptop.statusObject.title;
    },
    alert() {
      let alert;
      if (this.success.is) {
        alert = {
          type: 'success',
          icon: 'check',
          text: this.success.message,
        };
      }
      if (this.error.is) {
        alert = {
          type: 'danger',
          icon: 'times',
          text: this.error.message,
        };
      }
      return alert;
    }
  },
  data() {
    return {
      loading: false,
      success: {
        message: 'Статус обновлен',
        is: false,
      },
      error: {
        message: 'Произошла ошибка при обращении к серверу',
        is: false,
      },
      status: {
        value: '',
        placeholder: '',
        legend: 'Укажите новый статус ноутбука',
        label: 'Статус',
        options: []
      },
      comment: {
        value: '',
        placeholder: '',
        legend: 'Укажите комментарий к новому статусу',
        label: 'Комментарий',
      }
    }
  },
}
</script>

<style lang="css">
  .c-modal__body {
    min-height: 500px;
  }
  .c-modal__footer .c-alert {
    margin-bottom: 0;
  }
  .c-password-generator {
    width: 26px;
    margin-left: 10px;
    position: relative;
    top: -3px;
    opacity: .7;
    cursor: pointer;
    transition: .2s linear;
  }

  .c-password-generator:hover {
    opacity: 1;
  }

  .c-modal__body--status {
    min-height: 360px;
    height: 360px;
  }
  
  .c-modal__body--status .select2-results__options {
      max-height: 140px;
      overflow: scroll;
  }
</style>