<template>
  <div :id="modalId" v-show="open" :class="'c-modal c-modal--'+size+' modal fade'+(open ? ' show' : '')" tabindex="-1" role="dialog" :aria-labelledby="title" data-backdrop="static">
    <div class="c-modal__dialog modal-dialog" role="document">
        <div class="c-modal__content">
            <div class="c-modal__header">
                <h3 class="c-modal__title">
                  <i v-if="icon" :class="['fa', `fa-${icon}`]"></i>
                  {{ title }}
                </h3>

                <span class="c-modal__close" @click="handleClose" aria-label="Close">
                    <i class="fa fa-close"></i>
                </span>
            </div>

            <slot></slot>

            <div v-if="onCancel || onSubmit" class="c-modal__footer">
                <button v-if="onCancel" @click="onCancel" class="c-btn c-btn--secondary">{{ cancelBtnText }}</button>
                <button v-if="onSubmit" @click="onSubmit" class="c-btn c-btn--success">{{ submitBtnText }}</button>
            </div>

        </div><!-- // .c-modal__content -->
    </div><!-- // .c-modal__dialog -->
</div>
</template>

<script>
import {uid} from '@/js/helpers.js';
export default {
  name: 'Modal',
  updated() {
    if (this.open) {
      let drop = document.createElement('div');
      drop.id='modal-backdrop';
      drop.classList = 'modal-backdrop fade show';
      document.body.appendChild(drop);
      document.getElementById(this.modalId).style.display = 'block';
      document.body.classList.add('modal-open');
    } else {
      let modalNode = document.getElementById(this.modalId);
      let drop = document.getElementById('modal-backdrop');
      if (modalNode && drop) {
        modalNode.style.display = 'none';
        drop.remove();
      }
      document.body.classList.remove('modal-open');
    }
  },
  computed: {
    modalId() {
      return uid();
    }
  },
  props: {
    open: {
      type: Boolean,
      default: false,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    size: {
      validator: (value) => {
        return [
          'xsmall',
          'small',
          'medium',
          'large',
          'xlarge',
          'huge',
        ].indexOf(value) !== -1;
      }
    },
    cancelBtnText: {
      type: String
    },
    submitBtnText: {
      type: String
    },
    onCancel: {
      type: [Function, Boolean],
      default: false
    },
    onSubmit: {
      type: [Function, Boolean],
      default: false
    },
    handleClose: {
      type: Function,
      default: () => {},
    },
    icon: {
      type: [Boolean, String],
      default: false
    }
  }
}
</script>

<style lang="css">
  .modal-title i {
    font-size: 20px;
    margin-right: 5px;
    position: relative;
    top: 1px;
  }
</style>