<template>
  <div class="c-laptop__content">

    <LaptopAcitivity :history="laptop.history" />    
       

  </div>
</template>

<script>
import LaptopAcitivity from '@/components/laptop/LaptopActivity.vue';

export default {
  name: 'LaptopTabs',
  components: {
    LaptopAcitivity
  },
  props: {
    laptop: {
      type: Object,
      required: true
    }
  }
}
</script>