<template>
  <button :disabled="disabled" @click="action" :class="'c-btn c-btn--'+type+(fullwidth ? ' c-btn--fullwidth' : '')">
    <i v-if="icon" :class="'fa fa-'+icon+' u-mr-xsmall'"></i>
    {{ label }}
  </button>
</template>

<script>
export default {
  name: 'Btn',
  props: {
    label: {
      type: String,
      required: true
    },
    icon: {
      type: [String, Boolean],
      default: false
    },
    action: {
      type: Function,
      default: () => {return false; }
    },
    fullwidth: {
      type: Boolean,
      default: true
    },
    type: {
      default: 'info',
      validator: (value) => {
        return [
          'fancy', 
          'primary', 
          'secondary',
          'info',
          'success',
          'warning',
          'danger'
        ].indexOf(value) !== -1;
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="css">
  .c-btn:disabled {
    opacity: .5;
  }
</style>