<template>
  <td class="c-table__cell">
    <i :class="icon"></i>{{ akb }}%
  </td>
</template>
<script>
export default {
  name: 'Akb',
  computed: {
    akb() {
      return this.data.akb;
    },
    icon() {
      let icon;
      if (this.akb == 100) {
        icon = 'battery-full u-color-success';
      }
      if (this.akb >= 75 && this.akb < 100) {
        icon = 'battery-three-quarters u-color-success';
      }
      if (this.akb >= 50 && this.akb < 75) {
        icon = 'battery-half u-color-warning';
      }
      if (this.akb >= 25 && this.akb < 50) {
        icon = 'battery-quarter u-color-danger';
      }
      if (this.akb < 25) {
        icon = 'battery-empty u-color-danger';
      }
      return `fa fa-${icon} mr5`;
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>