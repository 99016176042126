<template>
  <div class="c-modal__preloader">
    <Preloader size="md" />
  </div>
</template>

<script>
import Preloader from '@/components/ui/preloader.vue';

export default {
  name: 'ModalPreloader',
  components: {
    Preloader,
  },
}
</script>

<style lang="css">
  .c-modal__preloader {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.7);
  }
</style>