<template>
  <div class="c-btn--fullwidth u-mr-small">
    <Btn label="Установить цену" type="info" icon="rub" :action="handleOpen" :fullwidth="true" />
    <Modal :open="open" :title="title" :handleClose="handleClose" size="medium">
      <SetSellLaptopPriceForm @clear-form="open=false"/>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/ui/modal.vue';
import Btn from '@/components/ui/btn.vue';
import SetSellLaptopPriceForm from '@/components/forms/SetSellLaptopPriceForm.vue';

export default {
  name: 'SetSellPriceActionModal',
  components: {
    SetSellLaptopPriceForm,
    Modal,
    Btn,
  },
  data() {
    return {
      open: false,
      title:  "Установка цены"
    };
  },
  methods: {
    handleOpen() {
      this.open = true;
    },
    handleClose() {
      this.open = false;
    },
  },
}
</script>