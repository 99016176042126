<template>
  <td class="c-table__cell">
    {{ title }}
  </td>
</template>
<script>
export default {
  name: 'Title',
  computed: {
    title() {
      return this.data.title;
    },
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>