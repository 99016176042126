<template>
  <div class="c-field">
    <label class="c-field__label mb10">{{ label }}</label>
    <div :class="'c-switch'+(value ? ' is-active' : '')" @click="handleClick">
      <input 
        class="c-switch__input"
        :id="switchId"
        type="checkbox"
        v-bind:value="value"
        v-on:input.stop="$emit('input', $event.target.checked)"
      />
      <label ref="label" class="c-switch__label" :for="switchId">{{ placeholder }}</label>
    </div>
  </div>
</template>

<script>
import {uid} from '@/js/helpers.js';

export default {
  name: 'SwitchField',
  computed: {
    switchId() {
      return uid();
    }
  },
  methods: {
    handleClick() {
      this.$refs.label.click();
    }
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'On'
    }
  }
}
</script>