<template>
  <div>
    <div class="c-modal__body c-modal__body--work">
      <ModalPreloader v-if="loading"/>
      <div class="row mb15">
        <div class="col">
          <h4 class="u-mb-medium">
            Учет ремонта
          </h4>

          <div class="row">
            <div class="col-sm-12">
              <div class="mb15">
                <TextField
                    :label="payable.title"
                    v-model="payable.value"
                    :placeholder="payable.placeholder"
                    :legend="payable.legend"
                    :options="payableActions"
                    v-bind:hiddenValue="payable.hiddenValue"
                    v-on:update:hiddenValue="payable.hiddenValue = $event"
                    :multiple="true"
                    :textarea="true"
                />
                <br>
                <h4 class="u-mb-medium">
                  Итоговая стоимость: {{ totalCost }} руб.
                </h4>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="c-modal__footer u-justify-end">
      <Alert
          v-if="success.is || error.is"
          :type="alert.type"
          :text="alert.text"
          :icon="alert.icon"
      />
      <Btn v-else :action="handleSubmit" icon="check" :label="'Учесть ремонт'" :fullwidth="false" type="success"/>
    </div>
  </div>
</template>

<script>
import TextField from '@/components/ui/Fields/TextField.vue';
import ModalPreloader from '@/components/ui/modal-preloader.vue';
import Alert from '@/components/ui/alert.vue';
import Btn from '@/components/ui/btn.vue';
import routes from '@/constants/server.js';
import axios from '@/js/http-client.js';
import {mapActions, mapState} from 'vuex';

export default {
  name: 'AddWorkForm',
  components: {
    TextField,
    ModalPreloader,
    Alert,
    Btn,
  },
  methods: {
    async handleSubmit() {
      this.loading = true;

      for (let payOption in this.options) {
        for (let i = 0; i < this.chosenPayableOptions.length; i++) {
          if (parseInt(this.chosenPayableOptions[i]) === parseInt(this.options[payOption].id)) {
            await this.saveAction(this.options[payOption]);
          }
        }
      }
      this.loading = false;
      setTimeout(this.clear.bind(this), 2000);
    },
    async saveAction(payOption) {
      let history = {
        price: parseInt(payOption.sum),
        laptop: this.laptop.id,
        description: payOption.title,
      };

      await this.handleSave(history);
    },
    async handleSave(data) {
      return axios.post(routes.laptop.work, data)
          .then(response => {
            console.log(response);
            this.success.is = true;
          })
          .catch(error => {
            console.log(error);
            this.error.is = true;
          });
    },
    getRepairActions() {
      axios.get(routes.payableAction.getRepairList)
          .then((res) => {
            res.data.forEach(function (payOption) {
              this.payableActions[payOption.id] = payOption.title;
            }, this);
            this.options = res.data;
            return res.data;
          })
          .catch((err) => {
            console.log(err);
            this.options = [];
            this.payableActions = [];
          });
    },
    clear() {
      this.success.is = false;
      this.error.is = false;
      this.$emit('clear-form');
      setTimeout(() => {
        this.fetchData(this.laptop.id);
      }, 300);
    },
    ...mapActions('laptop', [
      'fetchData',
    ]),
  },
  mounted() {
    this.getRepairActions();
  },
  watch: {
    payable: {
      handler: function (val) {
        this.totalCost = 0;
        this.chosenPayableOptions = val.hiddenValue.split(',');
        this.options.forEach(function (payOption) {
          for (let i = 0; i < this.chosenPayableOptions.length; i++) {
            if (parseInt(this.chosenPayableOptions[i]) === parseInt(payOption.id)) {
              this.totalCost += parseInt(payOption.sum);
            }
          }
        }, this);
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      laptop: state => state.laptop.laptop,
    }),
    formData() {
      return {
        price: parseInt(this.price.value),
        description: this.description.value,
        laptop: this.laptop.id,
      }
    },
    alert() {
      let alert;
      if (this.success.is) {
        alert = {
          type: 'success',
          icon: 'check',
          text: this.success.message,
        };
      }
      if (this.error.is) {
        alert = {
          type: 'danger',
          icon: 'times',
          text: this.error.message,
        };
      }
      return alert;
    }
  },
  data() {
    return {
      totalCost: 0,
      chosenPayableOptions: [],
      loading: false,
      payableActions: {},
      options: {},
      success: {
        message: 'Ремонт учтен',
        is: false,
      },
      error: {
        message: 'Произошла ошибка при обращении к серверу',
        is: false,
      },
      price: {
        value: '',
        placeholder: '',
        legend: 'Укажите стоимость ремонта',
        label: 'Стоимость',
      },
      description: {
        value: '',
        placeholder: '',
        legend: 'Опишите выполненную работу',
        label: 'Описание',
      },
      payable: {
        value: '',
        hiddenValue: '',
        placeholder: 'Оказанные услуги по ремонту',
        legend: 'Вы можете выбрать несколько видов услуг',
        label: 'Виды ремонта',
      },
    }
  },
}
</script>

<style lang="css">
.c-modal__body {
  min-height: 500px;
}

.c-modal__footer .c-alert {
  margin-bottom: 0;
}

.c-password-generator {
  width: 26px;
  margin-left: 10px;
  position: relative;
  top: -3px;
  opacity: .7;
  cursor: pointer;
  transition: .2s linear;
}

.c-password-generator:hover {
  opacity: 1;
}

.c-modal__body--work {
  height: 420px;
}

</style>