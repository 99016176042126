<template>
  <div>
    <Sidebar />
    <main class="o-page__content">
      <Topbar />
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 home-table-wrapper">
            <Preloader v-if="loading" />
            <Table
              v-else
              :action="action"
              :title="table.title"
              :total="+table.total"
              :headers="table.headers"
              :items="table.items"
            />
          </div>
          <div class="col-md-6 offset-md-3">
            <Pagination 
              :current="parseInt(this.table.current)"
              :total="parseInt(this.table.pages)"
              :handlePrev="loadPrevPage"
              :handleNext="loadNextPage"
            />
          </div>
        </div>
      </div>
    </main>
  </div> 
</template>

<script>
// @ is an alias to /src
import Topbar from '@/components/layout/Topbar.vue';
import Sidebar from '@/components/layout/Sidebar.vue';
import Table from '@/components/table/PartsTable.vue';
import Pagination from '@/components/ui/pagination.vue';
import Preloader from '@/components/ui/preloader.vue';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Parts',
  components: {
    Topbar,
    Sidebar,
    Preloader,
    Pagination,
    Table,
  },
  mounted() {
    this.fetchData(this.$router);
    // this.fetchData();
  },
  watch: {
    $route() {
      this.table.current = 1;
      this.fetchData(this.$router);
      // this.fetchData();
    }
  },
  computed: {
    ...mapGetters('parts', [
      'action',
      'loading',
      'error',
      'table',
    ]),
  },
  methods: {
    ...mapActions('parts', [
        'fetchData',
    ]),
    loadNextPage()
    {
      this.table.current++;
      this.fetchData(this.view);
    },
    loadPrevPage()
    {
      this.table.current--;
      this.fetchData(this.view);
    },
  },
  data() {
    return {};
  },
}
</script>

<style lang="css">
  .home-table-wrapper {
    min-height: calc(100vh - 160px);
    position: relative;
  }
  .home-table-wrapper .preloader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    display: block;
  }
</style>
