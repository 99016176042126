<template>
  <div class="o-page--center login-bg">
    <div class="o-page__card">
      <div class="c-card u-mb-xsmall">
          <header class="c-card__header u-pt-large">
              <a class="c-card__icon" href="#!">
                  <img src="img/logo.png" alt="Dashboard UI Kit">
              </a>
              <h1 class="u-h3 u-text-center u-mb-zero">
                <Logo />
              </h1>
              
          </header>
          
          <LoginForm 
            :login="login"
            :password="password"
            :error="error"
            :auth="auth"
            :loading="loading"
          />
      </div>

      <!-- <div class="o-line">
          <a class="u-text-mute u-text-small" href="register.html">Don’t have an account yet? Get Started</a>
          <a class="u-text-mute u-text-small" href="forgot-password.html">Forgot Password?</a>
      </div> -->
    </div>
  </div>
</template>

<script>
import Logo from '@/components/brand/logo.vue';
import LoginForm from '@/components/forms/LoginForm.vue';

import { mapActions } from 'vuex';

import routes from '@/constants/server.js';
import axios from '@/js/http-client.js';
import { saveUser, clearAuth } from '@/js/helpers.js';

export default {
  name: 'Login',
  components: {
    Logo,
    LoginForm,
  },
  methods: {
    ...mapActions('user', [
      'setUser'
    ]),

    auth() {
      clearAuth();

      let data = {
        username: this.login.value,
        password: this.password.value,
      }

      this.loading = true;
      axios.post(routes.auth.token, data)
        .then(response => {
          this.setUser(response.data.data);
          saveUser(response.data.data);
          this.error.is = false;
          this.$router.push('/views');
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
          this.error.is = true;
        });
    }
  },
  data() {
    return {
      loading: false,
      login: {
        label: "Логин",
        value: "",
        validate: {
          required: {
            error: "Введите логин",
          },
          valid: false
        }
      },
      password: {
        label: "Пароль",
        value: "",
        type: "password",
        validate: {
          required: {
            error: "Введите пароль",
          },
          valid: false
        }
      },
      error: {
        is: false,
        message: 'Неверный логин/пароль',
        icon: 'times'
      }
    };
  }
}
</script>

<style lang="css">
  .login-bg {
    background-image: url('/img/bg-stars.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
  }
</style>