<template>
  <td class="c-table__cell">
    {{ date }}
    <small class="u-block u-text-mute">
      <i class="fa fa-user-o created__user-icon"></i>{{ author }},
      <i class="fa fa-clock-o created__time-icon"></i>{{ time }}
    </small>
  </td>
</template>
<script>
export default {
  name: 'Created',
  computed: {
    date() {
      return this.data.date;
    },
    time() {
      return this.data.time;
    },
    author() {
      return this.data.author;
    },
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="css">
  .created__time-icon,
  .created__user-icon {
    margin-right: 5px;
  }
</style>