<template>
  <div>
    <Btn label="Добавить запчасть" type="success" icon="plus" :action="handleOpen" :fullwidth="false" />
    <Modal :open="open" :title="title" :handleClose="handleClose" size="medium">
      <AddUserForm @clear-form="open=false" />
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/ui/modal.vue';
import Btn from '@/components/ui/btn.vue';
import AddUserForm from '@/components/forms/AddPartForm.vue';

export default {
  name: 'AddPartModal',
  components: {
    AddUserForm,
    Modal,
    Btn,
  },
  data() {
    return {
      open: false,
      title:  "Добавить запчасть"
    };
  },
  methods: {
    handleOpen() {
      this.open = true;
    },
    handleClose() {
      this.open = false;
    },
  }
}
</script>