<template>
  <div>
    <Sidebar/>
    <main class="o-page__content">
      <Topbar/>
      <div class="container">
        <Breadcrumbs :items="this.breadcrumbs"/>
        <Preloader v-if="loading"/>
        <div class="row">
          <div class="col-xl-7">
            <EmployeeActivity/>
          </div>

          <div class="col-xl-5">
            <UserAccount v-bind:userName="firstName" v-bind:secondName="surname" v-bind:userMail="userEmail"
                         v-bind:uid="id" v-bind:userAvatar="getAvatar" v-bind:userRole="userRole"/>
          </div>

        </div>
      </div>
    </main>

  </div>
</template>

<script>
import Topbar from '@/components/layout/Topbar.vue';
import Sidebar from '@/components/layout/Sidebar.vue';
import Breadcrumbs from '@/components/ui/breadcrumbs.vue';
import Preloader from '@/components/ui/preloader.vue';
import EmployeeActivity from '@/components/employee/EmployeeActivity.vue';
import UserAccount from '@/components/employee/UserAccount.vue';
import {mapState, mapActions} from 'vuex';
import {getRole} from '@/js/helpers.js';
import {getUserId} from "../js/helpers";
import {isAdmin} from '@/js/helpers.js';

export default {
  name: 'SingleUser',
  components: {
    Topbar,
    Sidebar,
    Breadcrumbs,
    Preloader,
    EmployeeActivity,
    UserAccount,
  },
  watch: {
    '$route.params.search': {
      handler: function () {
        this.fetchData(this.id);
      },
      deep: true,
      immediate: true
    },
    user: function (oldData, newData) {
      if (this.id != getUserId() && !isAdmin()) {
        console.log(newData);
        this.$router.go(-1);
      }
    }
  },
  computed: {
    ...mapState({
      loading: state => state.employee.loading,
      user: state => state.employee.user,
      error: state => state.employee.error,
    }),
    id() {
      const {id} = this.$route.params;
      return id;
    },
    breadcrumbs() {
      return [
        {
          path: '/#/users/',
          title: 'Сотрудники'
        },
        {
          title: this.user.display_name
        }
      ];
    },
    firstName() {
      return this.user.first_name;
    },
    surname() {
      return this.user.second_name;
    },
    userEmail() {
      return this.user.user_email;
    },
    userRole() {
      return getRole(this.user.role);
    },
    getAvatar() {
      if (this.user.avatar) {
        return this.user.avatar;
      }
      if (this.avatar) {
        return this.avatar.value;
      }
      return '';
    },
  },
  methods: {
    ...mapActions('employee', [
      'fetchData',
    ]),
  },

}
</script>
<style lang="css">
.single-avatar .o-media {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
</style>