<template>
  <nav v-if="total > 0" class="c-pagination u-justify-between">
    <button
       :disabled="isFirstPage"
       class="c-pagination__control u-text-dark"
       @click="handlePrev"
      >
        <i class="fa fa-caret-left u-mr-xsmall"></i>Назад
    </button>
    <p class="c-pagination__counter">Страница {{ current }} из {{ total }}</p>
    <button
      :disabled="isLastPage"
      class="c-pagination__control u-text-dark"
      @click="handleNext"
    >
        Вперед<i class="fa fa-caret-right u-ml-xsmall"></i>
    </button>
  </nav>
</template>

<script>
export default {
  name: 'Pagination',
  computed: {
    isFirstPage() {
      return this.current === 1;
    },
    isLastPage() {
      return this.current === this.total;
    },
  },
  props: {
    current: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    handleNext: {
      type: Function,
      default: () => {}
    },
    handlePrev: {
      type: Function,
      default: () => {}
    },
  }
}
</script>

<style lang="css">
  .c-pagination__control:disabled {
    opacity: 0;
    cursor: initial;
  }
</style>