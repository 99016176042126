<template>
  <div class="row u-mb-large">
    <div class="col-sm-12">
        <div class="c-table-responsive@desktop">
          <table class="c-table c-table--highlight">
            <caption class="c-table__title">
                  Сотрудники
                  <span v-if="action && isAdmin()" class="c-table__title-action">
                    <component :is="action"></component>
                  </span>
              </caption>
            <thead class="c-table__head c-table__head--slim">
                <tr class="c-table__row">   
                    <!-- <th class="c-table__cell c-table__cell--head">id</th> -->
                    <th class="c-table__cell c-table__cell--head">Аватар</th>
                    <th class="c-table__cell c-table__cell--head">Имя</th>
                    <th class="c-table__cell c-table__cell--head">Логин</th>
                    <th class="c-table__cell c-table__cell--head">Email</th>
                    <th class="c-table__cell c-table__cell--head">Роль</th>
                    <th class="c-table__cell c-table__cell--head">Дата добавления</th>
                    <!-- <th class="c-table__cell c-table__cell--head">
                      <span class="u-hidden-visually">Действия</span>
                    </th> -->
                </tr>
            </thead>
            <tbody>
                <tr
                  v-for="(item, key) in items"
                  :key="key"
                  class="c-table__row"
                  @click="$router.push(`/user/${item.ID}`)"
                >
                  <td class="c-table__cell">
                    <Avatar :image="item.data.avatar" />
                  </td>
                  <td class="c-table__cell">
                    {{ item.data.display_name }}
                  </td>
                  <td class="c-table__cell">
                    {{ item.data.user_login }}
                  </td>
                  <td class="c-table__cell">
                    {{ item.data.user_email }}
                  </td>            
                  <td class="c-table__cell">
                    {{ getUserRole(item.roles[0]) }}
                  </td>
                  <td class="c-table__cell">
                    {{ formatUserDate(item.data.user_registered) }}
                  </td>
                  
                </tr>
            </tbody>
          </table>
        </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/ui/avatar.vue';
import { formatDate, getRole, isAdmin } from '@/js/helpers.js';

export default {
  name: 'UsersTable',
  components: {
    Avatar,
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    action: {
      type: [Object, Boolean],
      default: false
    }
  },
  methods: {
    isAdmin,
    getUserRole(slug) {
      return getRole(slug);
    },
    formatUserDate(date) {
      return formatDate(date);
    }
  }
}
</script>

<style lang="css">
  .c-badge {
    font-size: 10px;
    font-weight: 500;
    margin-right: 5px;
    margin-bottom: 5px;
  }
  .c-table__fields-cell {
    max-width: 300px;
    display: flex;
    flex-wrap: wrap;
  }
  .c-table__icon-cell {
    font-size: 20px;
    opacity: .5;
  }
</style>