import axios from '@/js/http-client.js';
import routes from '@/constants/server.js';
import AddPartModal from '@/components/ui/Modals/AddPartModal.vue';

export default {
    namespaced: true,
    state: {
        action: AddPartModal,
        loading: true,
        error: false,
        table: {
            total: 0,
            items: [],
            perPage: 10,
            current: 1,
            pages: 1
        }
    },
    getters: {
        action: state => state.action,
        loading: state => state.loading,
        error: state => state.error,
        table: state => state.table,
    },
    mutations: {
        startLoading: state => state.loading = true,
        stopLoading: state => state.loading = false,
        setTable: (state, table) => state.table = table,
        setError: state => state.error = true,
        unsetError: state => state.error = false,
    },
    actions: {
        fetchData(context, router) {
            context.commit('startLoading');

            // axios.get(routes.parts.list)
            const endpoint = `${routes.parts.list}?perPage=${context.state.table.perPage}&current=${context.state.table.current}`;
            axios.get(endpoint)
                .then((response) => {
                    context.commit('setTable', response.data);
                    context.commit('stopLoading');
                })
                .catch((error) => {
                    console.log(error);
                    this.error = true;
                    router.push('/login');
                    context.commit('stopLoading');

                });
        },
    }
}