import axios from '@/js/http-client.js';
import routes from '@/constants/server.js';

import router from '@/router/index.js';

import { clearAuth  } from '@/js/helpers.js';

export default {
  namespaced: true,
  state: {
    navigation: {
      laptops: [],
      views: [
        {
          path: '/views/',
          title: 'Представления',
          icon: 'fa-table',
          badge: false,
        }
      ],
      users: [
        {
          path: '/users/',
          title: 'Сотрудники',
          icon: 'fa-user',
          badge: false,
        }
      ],
      parts: [
        {
          path: '/parts/',
          title: 'Запчасти',
          icon: 'fa-wrench',
          badge: false
        }
      ],
      payableActions: [
        {
          path: '/payable-actions/',
          title: 'Целевые действия',
          icon: 'fa-crosshairs',
          badge: false
        }
      ],
    },
    notifications: [],
  },
  getters: {

  },
  mutations: {
    setLaptopsNav(state, items) {
      state.navigation.laptops = items;
    }
  },
  actions: {
    getLaptopsNav(context) {
      axios.get(routes.view.menu)
        .then(response => {
          console.log(response);
          if (response.data.statusCode == 403) {  
            clearAuth();
            router.push('/login');
          } else {
            context.commit("setLaptopsNav", response.data);
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
  }
}