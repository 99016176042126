<template>
  <div :class="['c-alert', `c-alert--${type}`]">
      <i :class="['c-alert__icon', 'fa', `fa-${icon}-circle`]"></i> 
      {{ text }}
  </div>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    text: {
      type: String,
      required: true,
    },
    type: {
      default: 'info',
      validator: (value) => {
        return [
          'fancy', 
          'primary', 
          'secondary',
          'info',
          'success',
          'warning',
          'danger'
        ].indexOf(value) !== -1;
      }
    },
    icon: {
      default: 'info',
      validator: (value) => {
        return [
          'check', 
          'info',
          'eclamation',
          'times',
        ].indexOf(value) !== -1;
      }
    }
  }
}
</script>

<style lang="css">
  .c-alert {
    font-size: 16px;
  }
</style>