<template>
  <ul class="c-sidebar__list">
    <SidebarItem
      v-for="(item, key) in items"
      :key="key"
      :title="item.title"
      :icon="item.icon"
      :path="item.path"
      :badge="item.badge"
    />
  </ul> 
</template>

<script>
import SidebarItem from '@/components/layout/Sidebar/sidebar-item.vue';

export default {
  name: 'SidebarList',
  components: {
    SidebarItem,
  },
  props: {
    items: {
      type: Array,
      required: true,
    }
  }
}
</script>