<template>
  <td class="c-table__cell">
    <span v-if="memory">
      <span 
        v-if="vendor"
        class="c-tooltip c-tooltip--top"
        :aria-label="'Производитель: '+vendor"
      >{{ memory }} GB*</span>
      <span v-else>{{ memory }} GB</span>
      <small v-if="type" class="u-block u-text-mute">
        <i style="opacity: .5" class="fa fa-microchip mr5"></i>{{ type }}
      </small>
    </span>
    <span v-else>—</span>
  </td>
</template>
<script>
export default {
  name: 'Ram',
  computed: {
    type() {
      return this.data.type;
    },
    memory() {
      return this.data.memory;
    },
    vendor() {
      return this.data.vendor;
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>