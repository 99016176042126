<template>
  <div>
    <i :class="`fa fa-circle-o u-color-${color} u-mr-xsmall`"></i>
    {{ title }}
  </div>
</template>
<script>
export default {
  name: 'LaptopStatus',
  computed: {
    title() {
      return this.data.title;
    },
    color() {
      return this.data.color;
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>