<template>
  <td class="c-table__cell">
    <span :class="'u-color-'+color">{{ sold }} ₽</span>
    <small class="u-block u-text-mute">
      <i class="fa fa-user-o mr5"></i>{{ seller }}, {{ sale }} ₽
    </small>
  </td>
</template>
<script>
export default {
  name: 'Sell',
  computed: {
    sold() {
      return (+this.data.sold).toLocaleString();
    },
    sale() {
      return (+this.data.sale).toLocaleString();
    },
    color() {
      return this.delta >= 0 ? 'success' : 'danger';
    },
    delta() {
      return this.data.sold - this.data.sale;
    },
    seller() {
      return this.data.seller;
    },
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>