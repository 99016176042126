<template>
  <div>
    <div class="c-avatar-uploader">

      <Avatar
        :image="url"
        :click="click"
        :title="title"
        :subtitle="subtitle"
        :size="size"
       />

      <slot
          v-if="status.loading"
          name="loading"
          :status="status"
        >
        <Preloader />
      </slot>

      <input
        type="file"
        ref="file"
        name="file"
        @change="select($event.target.name, $event.target.files)"
        style="display: none"
      />
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/ui/avatar.vue';
import Preloader from '@/components/ui/preloader.vue';

export default {
  name: 'AvatarUploadable',
  components: {
    Avatar,
    Preloader,
  },
  props: {
    url: {
      type: String,
      required: true
    },

    request: {
      type: Function,
      required: true
    },

    rename: {
      type: Function,
      required: false,
      default: file => file.name
    },

    field: {
      type: String,
      required: false,
      default: 'file'
    },

    clickable: {
      type: Boolean,
      required: false,
      default: true
    },

    maxSize: {
      type: Number,
      required: false,
      default: 2048
    },

    headers: {
      type: Object,
      required: false,
      default: () => ({})
    },

    title: {
      type: [Boolean, String],
      default: false
    },

    subtitle: {
      type: [Boolean, String],
      default: false
    },

    size: {
      default: 'large',
      validator(value) {
        return [
          'xsmall',
          'small',
          'medium',
          'large',
          'xlarge',
        ].indexOf(value) !== -1;
      }
    },

    // TODO: Consider just using $attrs instead
    avatar: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },

  data: () => ({
    status: {
      loading: false,
      progress: 0
    }
  }),

  methods: {
    click () {
      if (!this.status.loading) {
        if (!this.url) {
          this.choose()
        } else {
          this.replace()
        }
      } else {
        this.$emit('cancel')
      }
    },

    choose () {
      return this.$refs.file.click()
    },

    replace () {
      if (this.$listeners.replace) {
        this.$emit('replace')
      } else {
        this.choose()
      }
    },

    select (field, file) {
      const [ image ] = file
      const { maxSize } = this

      if (file.length > 0) {
        const size = image.size / 1024

        if (!image.type.match('image.*')) {
          this.$emit('error-type')
        } else if (size > maxSize) {
          this.$emit('error-size')
        } else {
          this.upload(image)
        }
      } else {
        this.$emit('error-empty')
      }
    },

    async upload (file) {
      this.status.loading = true

      const form = new FormData()

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...this.headers
        },

        onUploadProgress: function (event) {
          if (event.lengthComputable) {
            const progress = (event.loaded / event.total) * 100

            this.status.progress = progress

            this.$emit('progress', progress)
          }
        }.bind(this)
      }

      form.append(this.field, file, this.rename(file))

      try {
        const upload = await this.request(form, config)

        this.$emit('success', upload)
      } catch (e) {
        this.$emit('failed', e)
      } finally {
        this.status.loading = false
        this.status.progress = 0
      }
    }
  }
}
</script>

<style lang="css">
  .c-avatar-uploader .c-avatar {
    cursor: pointer;
  }

  .c-avatar-uploader small:before {
    display: none !important;
  }

  .c-avatar-uploader small {
    margin-left: 0 !important;
  }

  .c-avatar-uploader .o-media__body {
    color: rgb(53, 64, 82);
  }
</style>
