<template>
  <div class="c-btn--fullwidth u-mr-small">
    <Btn label="Ремонт" type="info" icon="wrench" :action="handleOpen" :fullwidth="true" />
    <Modal :open="open" :title="title" :handleClose="handleClose" size="medium">
      <AddWorkForm @clear-form="open=false"/>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/ui/modal.vue';
import Btn from '@/components/ui/btn.vue';
import AddWorkForm from '@/components/forms/AddWorkForm.vue';

export default {
  name: 'AddWorkModal',
  components: {
    AddWorkForm,
    Modal,
    Btn,
  },
  data() {
    return {
      open: false,
      title:  "Ремонт"
    };
  },
  methods: {
    handleOpen() {
      this.open = true;
    },
    handleClose() {
      this.open = false;
    },
  },
}
</script>