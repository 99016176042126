<template>
  <div class="o-media" @click="click">
      <div class="o-media__img u-mr-xsmall">
          <div :class="['c-avatar', sizeClass]">
              <img class="c-avatar__img" :src="image" alt="">
          </div>
      </div>
      <div v-if="title" class="o-media__body">
          {{ title }}
          <small v-if="subtitle" class="u-block u-text-mute">{{ subtitle }}</small>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  computed: {
    sizeClass() {
      return `c-avatar--${this.size}`;
    }
  },
  props: {
    image: {
      type: String,
      required: true,
    },
    title: {
      type: [Boolean, String],
      default: false
    },
    subtitle: {
      type: [Boolean, String],
      default: false
    },
    click: {
      type: Function,
      default: () => {}
    },
    size: {
      default: 'large',
      validator(value) {
        return [
          'xsmall',
          'small',
          'medium',
          'large',
          'xlarge',
        ].indexOf(value) !== -1;
      }
    }
  }
}
</script>