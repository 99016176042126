export const dropdownMixin = {
    mounted() {
        document.addEventListener('click', this.close)
    },
    beforeDestroy () {
        document.removeEventListener('click',this.close)
    },
    methods: {
        close (e) {
            if (!this.$el.contains(e.target)) {
                this.open = false
            }
        }
    }
}