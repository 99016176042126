import axios from '@/js/http-client.js';
import routes from '@/constants/server.js';


export default {
  namespaced: true,
  state: {
    loading: true,
    error: false,
    laptop: {}
  },
  getters: {
    action: state => state.action,
    loading: state => state.loading,
    error: state => state.error,
    laptop: state => state.laptop,
  },
  mutations: {
    startLoading: state => state.loading = true,
    stopLoading: state => state.loading = false,
    setLaptop: (state, laptop) => state.laptop = laptop,
    unsetLaptop: (state) => state.laptop = null,
    setError: state => state.error = true,
    unsetError: state => state.error = false,
  },
  actions: {
    fetchData(context, id) {
      context.commit('startLoading');

      axios.get(routes.laptop.single+id)
        .then((response) => {
          console.log('LAPTOPDATA');
          console.log(response.data);
          context.commit('setLaptop', response.data);
          context.commit('stopLoading');
        })
        .catch((error) => {
          console.log(error);
          context.commit('setError');
          context.commit('stopLoading');
        });
    },
    clearLaptopStore(context) {
      context.commit('unsetLaptop');
    }
  }
}