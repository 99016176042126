<template>
  <td class="c-table__cell">
    <span v-if="model">
      {{ model }}
      <small class="u-block u-text-mute">
        <i style="opacity: .5" class="fa fa-microchip mr5"></i>{{ memory }} GB
      </small>
    </span>
    <span v-else>—</span>
  </td>
</template>
<script>
export default {
  name: 'Gpu',
  computed: {
    model() {
      return this.data.model;
    },
    memory() {
      return this.data.memory;
    },
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>