<template>
  <div class="c-card u-p-medium u-mb-medium">

    <div class="activity-heading">
      <h5 class="u-mb-medium">Активность</h5>
      <span>
        <span class="u-opacity-medium u-text-xsmall">Сумма бонусов за выбранный период:</span> <span>{{
          totalSum
        }} р.</span>
      </span>
    </div>

    <span class="c-divider u-mv-medium"></span>
    <div class="row">
      <div class="col">
        <label for="startDate" class="c-field__label">Дата начала</label>
        <div class="c-field__input-wrapper">
          <datepicker
              :language="ru"
              v-model="dateFrom"
              :format="format"
              id="startDate"
              placeholder="Выберите дату"
              input-class="c-input"
          >
          </datepicker>
        </div>
      </div>
      <div class="col">
        <label for="endDate" class="c-field__label">Дата конца</label>
        <datepicker
            :language="ru"
            v-model="dateTo"
            :format="format"
            id="endDate"
            placeholder="Выберите дату"
            input-class="c-input"
        >
        </datepicker>
      </div>
    </div>
    <br>
    <Preloader v-if="loading"/>
    <div v-else class="c-activity-blocks">
      <div class="c-activity-block">
        <div v-if="entries.length > 0" class="c-feed">
          <div v-for="(entry, index) in entries" :key="index" :class="['c-feed__item']">
            <p>
              <span v-html="prepareEntryTitle(entry.title)"></span><br>
              <span class="u-mr-10 u-opacity-medium">{{ getLabel(entry.action) }}</span>
              <Badge v-if="entry.sum > 0" :label="'+'+entry.sum" color="success"/>
            </p>
            <p class="c-feed__comment" v-if="entry.comment.length > 0"><i class="fa fa-comments-o"
                                                                          style="opacity: .5"></i> {{ entry.comment }}
            </p>
            <span class="c-feed__meta">{{ entry.date }}</span>

          </div>
        </div>
        <div v-else>
          <p class="u-opacity-medium">Нет активности за указанный период</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Preloader from '@/components/ui/preloader.vue';
import Datepicker from 'vuejs-datepicker';
import {ru} from 'vuejs-datepicker/dist/locale'

import axios from '@/js/http-client.js';
import routes from '@/constants/server.js';
import moment from 'moment';

import Badge from '@/components/ui/badge.vue';

import {getActionLabel} from '@/js/helpers.js';

export default {
  name: 'EmployeeActivity',
  components: {
    Preloader,
    Badge,
    Datepicker
  },
  data: () => {
    return {
      entries: [],
      loading: false,
      error: false,
      dateFrom: moment().clone().startOf('month').format('YYYY-MM-DD'),
      dateTo: moment().clone().add(1, 'days').format('YYYY-MM-DD'),
      ru: ru,
      format: "yyyy-MM-dd",
    };
  },
  watch: {
    dateFrom: function (newVal) {
      this.dateFrom = this.convert(newVal);
      this.debouncedFetchData();
    },
    dateTo: function (newVal) {
      this.dateTo = this.convert(newVal);
      this.debouncedFetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    id() {
      const {id} = this.$route.params;
      return id;
    },
    query() {
      return `?dateFrom=${this.dateFrom}&dateTo=${this.dateTo}`;
    },
    totalSum() {
      let total = 0;

      this.entries.map((entry) => {
        total += entry.sum;
      });

      return total;
    }
  },
  methods: {
    debouncedFetchData() {
      this.__timer = setTimeout(this.fetchData.bind(this), 300);
    },
    fetchData() {
      this.loading = true;
      axios.get(routes.history.list + this.id + this.query)
          .then((res) => {
            this.entries = res.data;
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
    },
    getLabel(slug) {
      return getActionLabel(slug);
    },
    prepareEntryTitle(title) {
      return title.replace(/\[(.*?)::(part|laptop)::(\d{1,6})\]/g, '<a href="/#/$2/$3">$1</a>');
    },
    convert(currentDate) {
      if (typeof currentDate === 'object') {
        let mm = currentDate.getMonth() + 1; // getMonth() is zero-based
        let dd = currentDate.getDate();

        return [currentDate.getFullYear(),
          (mm > 9 ? '' : '0') + mm,
          (dd > 9 ? '' : '0') + dd
        ].join('-');
      }

      return currentDate;
    }
  }
}
</script>
<style lang="css">
.activity-heading {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: -40px;
}

.u-mr-10 {
  margin-right: 10px;
}

.c-field__input-wrapper {
  position: relative;
}

.c-field__input-wrapper .preloader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: initial !important;
  right: 10px !important;
  margin: auto;
}

/*.vdp-datepicker>input {*/
/*  background-color: red;*/
/*  display: block;*/
/*  width: 100%;*/
/*  margin: 0;*/
/*  padding: 0.59375rem 0.9375rem;*/
/*  -webkit-transition: all .3s;*/
/*  transition: all .3s;*/
/*  border: 1px solid #dfe3e9;*/
/*  border-radius: 4px;*/
/*  !*background-color: #fff;*!*/
/*  color: #354052;*/
/*  font-size: 0.875rem;*/
/*  font-weight: 400;*/
/*  resize: none;*/
/*}*/
</style>