<template>
  <div class="part-description">
    <div v-if="params.length > 1">
      <div v-for="(param, key) in params" :key="key">
        <span class="u-text-mute">{{ param.split(':')[0].trim() }}: </span>
        {{ param.split(':')[1].trim() }}
      </div>
    </div>
    <div v-else-if="params.length == 1">{{ content }}</div>
    <div v-else>—</div>
  </div>
</template>

<script>
export default {
  name: 'PartDescription',
  props: {
    content: {
      type: String,
      required: true
    }
  },
  computed: {
    params() {
      return this.content.split("\n");
    }
  }
}
</script>