export default {
  search: '/api/v1/search/term/',
  searchExisting: '/api/v1/search/existing-term/',
  laptop: {
    search: '/api/v1/laptop/search',
    save: '/api/v1/laptop/',
    list: '/api/v1/laptop/list/',
    filter: '/api/v1/laptop/filter/',
    single: '/api/v1/laptop/',
    comment: '/api/v1/laptop/comment/',
    work: '/api/v1/laptop/work/',
    install: '/api/v1/laptop/install/',
    uninstall: '/api/v1/laptop/uninstall/',
    status: '/api/v1/laptop/status/',
    clone: '/api/v1/laptop/clone/',
    sell: '/api/v1/laptop/sell/',
    setSell: '/api/v1/laptop/setSell/'
  },
  view: {
    add: '/api/v1/view/',
    list: '/api/v1/view/list/',
    menu: '/api/v1/view/menu/',
    delete: '/api/v1/view/',
    update: '/api/v1/view/',
  },
  auth: {
    token: '/jwt-auth/v1/token',
  },
  user: {
    search: '/api/v1/user/search',
    list: '/api/v1/user/list',
    avatar: '/api/v1/user/avatar',
    save: '/api/v1/user/',
    update: '/api/v1/user/',
    statuses: '/api/v1/user/statuses/',
    single: '/api/v1/user/',
  },
  parts: {
    search: '/api/v1/parts/search/',
    list: '/api/v1/parts/list/',
    save: '/api/v1/parts/'
  },
  history: {
    list: '/api/v1/history/list/',
  },
  payableAction: {
    save: '/api/v1/payable-action/',
    list: '/api/v1/payable-action/list/',
    search: '/api/v1/payable-action/search-types/',
    getRepairList: '/api/v1/payable-action/repair-list/',
  },
}