<template>
  <form @submit.prevent="auth" class="c-card__body" ref="loginForm">
    <TextField
        :label="login.label"
        v-model="login.value"
        :validate="login.validate"
    />

    <TextField
        :label="password.label"
        v-model="password.value"
        :type="password.type"
        :validate="password.validate"
    />
    <Alert v-show="error.is" :text="error.message" :icon="error.icon" type="danger"/>
    <button style="height:39px" @click.prevent="auth" class="c-btn c-btn--info c-btn--fullwidth" type="button">
      <span v-if="!loading">Войти</span>
      <Preloader v-else size="xs" :inversion="true"/>
    </button>
  </form>
</template>

<script>
import Alert from '@/components/ui/alert.vue';
import Preloader from '@/components/ui/preloader.vue';

export default {
  name: 'LoginForm',
  components: {
    TextField: () => import('@/components/ui/Fields/TextField.vue'),
    Alert,
    Preloader,
  },
  mounted() {
    this.$refs.loginForm.addEventListener('keyup', (e) => {
      if (e.keyCode == 13) {
        this.auth();
      }
    });
  },
  props: {
    login: {
      type: Object
    },
    password: {
      type: Object
    },
    error: {
      type: Object
    },
    auth: {
      type: Function
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>