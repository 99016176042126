import axios from '@/js/http-client.js';
import routes from '@/constants/server.js';


export default {
  namespaced: true,
  state: {
    loading: true,
    error: false,
    user: {}
  },
  getters: {
    action: state => state.action,
    loading: state => state.loading,
    error: state => state.error,
    user: state => state.user,
  },
  mutations: {
    startLoading: state => state.loading = true,
    stopLoading: state => state.loading = false,
    setUser: (state, user) => state.user = user,
    setError: state => state.error = true,
    unsetError: state => state.error = false,
  },
  actions: {
    fetchData(context, id) {
      context.commit('startLoading');

      axios.get(routes.user.single+id)
        .then((response) => {

          context.commit('setUser', response.data);
          context.commit('stopLoading');
        })
        .catch((error) => {
          console.log(error);
          context.commit('setError');
          context.commit('stopLoading');
        });
    }
  }
}