<template>
  <div class="repeater mb15">
    <div v-for="(row, key) in dynamicRows" :key="key" class="o-line mb15">
      <div class="col" v-for="(item, key) in row" :key="key">
         <TextField
          :label="item.label"
          v-model="item.value"
          :placeholder="item.placeholder"
          v-bind:hiddenValue="item.hiddenValue"
          v-on:update:hiddenValue="item.hiddenValue = $event"
          :legend="item.legend"
          :options="item.options"
          :endpoint="key == 1 ? `${routes.search}${row[0].hiddenValue}/` : false"
        />
      </div> 
      <Btn
        icon="trash"
        label="Удалить"
        type="secondary"
        :action="() => {deleteRow(key)}"
        :fullwidth="false"
        :disabled="key===0"
      />
    </div>
    <div class="text-right">
      <Btn icon="plus" :label="addLabel" type="secondary" :action="addRow" :fullwidth="false" />
    </div>
  </div>
</template>

<script>
import TextField from '@/components/ui/Fields/TextField.vue';
import Btn from '@/components/ui/btn.vue';

import routes from '@/constants/server.js';

export default {
  name: 'AutofiltersField',
  components: {
    TextField,
    Btn,
  },
  created() {
    //Копируем первый элемент ряда для "заготовки" и запоминаем его
    this.initialRow = Array.from(JSON.parse(JSON.stringify(this._props.rows[0])));
    this.dynamicRows = Array.from(JSON.parse(JSON.stringify(this._props.rows)));
  },
  methods: {
    addRow() {
      //Копируем массив
      let newRow = Array.from(JSON.parse(JSON.stringify(this.initialRow)));
      this.dynamicRows.push(newRow);
    },
    deleteRow(key) {
      this.dynamicRows.splice(key, 1);
    }
  },
  watch: {
    rows(newRows) {
      this.dynamicRows = newRows;
    }
  },
  data() {
    return {
      dynamicRows: [],
      routes: routes,
    };
  },
  props: {
    rows: {
      type: Array,
      required: true
    },
    addLabel: {
      type: String,
      default: 'Добавить фильтр'
    }
  }
}
</script>