<template>
  <td class="c-table__cell">
    <span v-if="model">
      {{ model }}
      <small class="u-block u-text-mute">
        <span v-if="min_freq">
          <i style="opacity: .5" class="fa fa-microchip mr5"></i>{{ description }} 
        </span>
      </small>
    </span>
    <span v-else>—</span>
  </td>
</template>
<script>
export default {
  name: 'Cpu',
  computed: {
    model() {
      return this.data.model;
    },
    min_freq() {
      return this.data.min_freq;
    },
    description() {
      let r = this.data.min_freq;
      if (this.data.max_freq) {
        r += '-'+this.data.max_freq;
      }
      r += ' MHz';
      if (this.data.cores) {
        r += ', '+ this.data.cores+' cores';
      }
      return r;
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>