<template>
  <div>
    <div class="c-card u-p-medium u-mb-medium">

      <div class="u-text-center">
        <div class="single-avatar">
          <AvatarUploadable
              :request="handleAvatarUpload"
              size="xlarge"
              :url="avatar.value"
          />
        </div>
      </div>

      <br>

      <div class="row">
        <div class="col-sm-6">
          <div class="mb15">
            <TextField
                :label="name.label"
                v-model.trim="name.value"
                :placeholder="name.placeholder"
                :legend="name.legend"
            />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="mb15">
            <TextField
                :label="surname.label"
                v-model.trim="surname.value"
                :placeholder="surname.placeholder"
                :legend="surname.legend"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <div class="mb15">
            <TextField
                :label="email.label"
                v-model.trim="email.value"
                :placeholder="email.placeholder"
                :legend="email.legend"
            />
          </div>
        </div>

        <div class="col-sm-6">
          <div class="mb15 o-line">
            <TextField
                :label="password.label"
                v-model.trim="password.value"
                :placeholder="password.placeholder"
                :legend="password.legend"
                type="password"
            />
            <span
                @click="handlePasswordGeneration"
                aria-label="Сгенерировать пароль"
                class="c-password-generator c-tooltip c-tooltip--top"
            >
                  <img src="/img/dice.svg" alt="Сгенерировать пароль">
                </span>
          </div>
        </div>
      </div>

      <div class="row" v-if="isAdmin()">
        <div class="col-sm-12">
          <div class="mb15">
            <TextField
                :label="role.label"
                v-model="role.value"
                :placeholder="role.placeholder"
                :legend="role.legend"
                :options="role.options"
                v-bind:hiddenValue="role.hiddenValue"
                v-on:update:hiddenValue="role.hiddenValue = $event"
            />
          </div>
        </div>
      </div>

      <br>

      <div class="u-justify-center">
        <Alert
            v-if="success.is || error.is"
            :type="alert.type"
            :text="alert.text"
            :icon="alert.icon"
        />
        <Btn v-else :action="handleSubmit" icon="floppy-o" :label="'Сохранить'" :fullwidth="true"
             type="success"/>
      </div>

    </div>
  </div>
</template>

<script>
import TextField from '@/components/ui/Fields/TextField.vue';
import Btn from '@/components/ui/btn.vue';
import Alert from '@/components/ui/alert.vue';
import AvatarUploadable from '@/components/ui/avatar-uploadable.vue';
import {mapState} from 'vuex';
import routes from '@/constants/server.js';
import axios from '@/js/http-client.js';
import {PasswordGeneratorMixin} from '../mixins/PasswordGeneratorMixin';
import {isAdmin} from '@/js/helpers.js';
import roles from '@/constants/roles.js';

export default {
  name: "UserAccount",
  components: {
    TextField,
    AvatarUploadable,
    Btn,
    Alert,
  },
  props: ['uid', 'userName', 'secondName', 'userMail', 'userAvatar', 'userRole'],
  mixins: [PasswordGeneratorMixin],
  data() {
    return {
      id: {
        value: this.uid
      },
      error: {
        message: 'Произошла ошибка при обращении к серверу',
        is: false,
      },
      success: {
        message: 'Данные успешно сохранены',
        is: false,
      },
      email: {
        placeholder: '',
        legend: 'Адрес электронной почты',
        label: 'Email',
        value: this.userMail,
      },
      name: {
        placeholder: '',
        label: 'Имя',
        legend: 'Ваше имя',
        value: this.userName
      },
      surname: {
        placeholder: '',
        label: 'Фамилия',
        legend: 'Ваша фамилия',
        value: this.secondName,
      },
      password: {
        value: '',
        placeholder: '',
        label: 'Пароль',
        legend: 'Только латинские буквы и цифры',
      },
      role: {
        label: 'Роль',
        placeholder: 'Менеджер',
        legend: 'Выберите роль сотрудника',
        options: roles,
        hiddenValue: '',
      },
      avatar: {
        value: this.userAvatar,
      }
    }
  },
  watch: {
    uid: function (newId) {
      this.id.value = newId;
    },
    avatar: function (newId) {
      this.avatar.value = newId;
    },
    userName: function (newVal) {
      this.name.value = newVal;
    },
    secondName: function (newVal) {
      this.surname.value = newVal;
    },
    userMail: function (newVal) {
      this.email.value = newVal;
    },
    userAvatar: function (newVal) {
      this.avatar.value = newVal;
    },
    userRole: function (newVal) {
      this.role.value = newVal;
      for (const [key, value] of Object.entries(roles)) {
        if (value == newVal) {
          this.role.hiddenValue = key;
        }
      }
    },
  },
  computed: {
    ...mapState({
      user: state => state.employee.user,
    }),
    alert() {
      let alert;
      if (this.success.is) {
        alert = {
          type: 'success',
          icon: 'check',
          text: this.success.message,
        };
      }
      if (this.error.is) {
        alert = {
          type: 'danger',
          icon: 'times',
          text: this.error.message,
        };
      }
      return alert;
    },
    formData() {
      return {
        email: this.email.value,
        name: this.name.value,
        surname: this.surname.value,
        password: this.password.value,
        avatar: this.avatar.value,
        id: this.id.value,
        role: this.role.hiddenValue,
      }
    },
  },
  methods: {
    isAdmin,
    handleAvatarUpload(form, config) {
      axios.post(routes.user.avatar, form, config)
          .then(response => {
            this.avatar.value = response.data.url;
          })
          .catch(error => {
            console.log(error);
          });
    },
    handleSubmit() {
      this.loading = true;
      axios.put(routes.user.update, this.formData)
          .then(response => {
            console.log(response);
            this.success.is = true;
            setTimeout(() => this.success.is = false, 3000)
          })
          .catch(error => {
            console.log(error);
            this.error.is = true;
            setTimeout(() => this.error.is = false, 2000)
          });
    },
  },
}
</script>

<style scoped>

</style>