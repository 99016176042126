<template>
  <div>
    <h4 class="u-mb-medium">Процессор и система</h4>
    <!-- START: ROW -->
    <div class="row">
      <div class="col-sm-6 col-md-3 u-mb-small">
        <TextField
            :label="cpuModel.label"
            v-model="cpuModel.value"
            :placeholder="cpuModel.placeholder"
            :legend="cpuModel.legend"
            :endpoint="cpuModel.endpoint"
        />
      </div>
      <div class="col-sm-6 col-md-3 u-mb-small">
        <TextField
            :label="cpuMinFreq.label"
            v-model="cpuMinFreq.value"
            :placeholder="cpuMinFreq.placeholder"
            :legend="cpuMinFreq.legend"
        />
      </div>
      <div class="col-sm-6 col-md-3 u-mb-small">
        <TextField
            :label="cpuMaxFreq.label"
            v-model="cpuMaxFreq.value"
            :placeholder="cpuMaxFreq.placeholder"
            :legend="cpuMaxFreq.legend"
        />
      </div>
      <div class="col-sm-6 col-md-3 u-mb-small">
        <TextField
            :label="cpuCores.label"
            v-model="cpuCores.value"
            :placeholder="cpuCores.placeholder"
            :legend="cpuCores.legend"
        />
      </div>
      <div class="col-sm-6 col-md-3 u-mb-small">
        <TextField
            :label="os.label"
            v-model="os.value"
            :placeholder="os.placeholder"
            :legend="os.legend"
            :endpoint="os.endpoint"
        />
      </div>
      <div class="col-sm-6 col-md-3 u-mb-small">
        <TextField
            :label="akb.label"
            v-model="akb.value"
            :placeholder="akb.placeholder"
            :legend="akb.legend"
        />
      </div>
    </div>
    <!-- END: ROW -->
    <h4 class="u-mb-medium">Оперативная память</h4>
    <!-- START: ROW -->
    <div class="row">
      <div class="col-sm-6 col-md-3 u-mb-small">
        <TextField
            :label="ramVendor.label"
            v-model="ramVendor.value"
            :placeholder="ramVendor.placeholder"
            :legend="ramVendor.legend"
            :endpoint="ramVendor.endpoint"
        />
      </div>
      <div class="col-sm-6 col-md-3 u-mb-small">
        <TextField
            :label="ramMemory.label"
            v-model="ramMemory.value"
            :placeholder="ramMemory.placeholder"
            :legend="ramMemory.legend"
        />
      </div>
      <div class="col-sm-6 col-md-3 u-mb-small">
        <TextField
            :label="ramFreq.label"
            v-model="ramFreq.value"
            :placeholder="ramFreq.placeholder"
            :legend="ramFreq.legend"
        />
      </div>
      <div class="col-sm-6 col-md-3 u-mb-small">
        <TextField
            :label="ramType.label"
            v-model="ramType.value"
            :placeholder="ramType.placeholder"
            :legend="ramType.legend"
            :endpoint="ramType.endpoint"
        />
      </div>
    </div>
    <!-- END: ROW -->
    <h4 class="u-mb-medium">Видеокарта</h4>
    <!-- START: ROW -->
    <div class="row">
      <div class="col-sm-6 col-md-3 u-mb-small">
        <TextField
            :label="gpuModel.label"
            v-model="gpuModel.value"
            :placeholder="gpuModel.placeholder"
            :legend="gpuModel.legend"
            :endpoint="gpuModel.endpoint"
        />
      </div>
      <div class="col-sm-6 col-md-3 u-mb-small">
        <TextField
            :label="gpuMemory.label"
            v-model="gpuMemory.value"
            :placeholder="gpuMemory.placeholder"
            :legend="gpuMemory.legend"
        />
      </div>
      <div class="col-sm-6 col-md-3 u-mb-small">
        <TextField
            :label="gpuFreq.label"
            v-model="gpuFreq.value"
            :placeholder="gpuFreq.placeholder"
            :legend="gpuFreq.legend"
        />
      </div>
    </div>
    <!-- END: ROW -->
    <!-- END: ROW -->
    <h4 class="u-mb-medium">Экран</h4>
    <!-- START: ROW -->
    <div class="row">
      <div class="col-sm-6 col-md-3 u-mb-small">
        <TextField
            :label="screenDiagonal.label"
            v-model="screenDiagonal.value"
            :placeholder="screenDiagonal.placeholder"
            :legend="screenDiagonal.legend"
            :endpoint="screenDiagonal.endpoint"
        />
      </div>
      <div class="col-sm-6 col-md-3 u-mb-small">
        <TextField
            :label="screenResolution.label"
            v-model="screenResolution.value"
            :placeholder="screenResolution.placeholder"
            :legend="screenResolution.legend"
            :endpoint="screenResolution.endpoint"
        />
      </div>
      <div class="col-sm-6 col-md-3 u-mb-small">
        <TextField
            :label="screenType.label"
            v-model="screenType.value"
            :placeholder="screenType.placeholder"
            :legend="screenType.legend"
            :endpoint="screenType.endpoint"
        />
      </div>
      <div class="col-sm-6 col-md-3 u-mb-small">
        <TextField
            :label="screenMatrix.label"
            v-model="screenMatrix.value"
            :placeholder="screenMatrix.placeholder"
            :legend="screenMatrix.legend"
            :endpoint="screenMatrix.endpoint"
        />
      </div>
    </div>
    <!-- END: ROW -->
    <h4 class="u-mb-medium">Жесткие диски</h4>
    <div class="row">
      <RepeaterField :rows="hards" addLabel="Добавить жесткий диск" />
    </div>
  </div>
</template>

<script>
import TextField from '@/components/ui/Fields/TextField.vue';
import RepeaterField from '@/components/ui/Fields/repeater-field.vue';

import routes from '@/constants/server.js';

export default {
  name: 'TechTabForm',
  components: {
    TextField,
    RepeaterField,
  },
  data() {
    return {
      cpuModel: {
        value: '',
        placeholder: 'Intel Core i5-1035G7',
        legend: 'Выберите модель из списка',
        label: 'Модель',
        endpoint: `${routes.search}cpu-model/`,
        error: 'Укажите модель процессора'
      },
      cpuMinFreq: {
        value: '',
        placeholder: '2700',
        legend: 'Укажите в MHz',
        label: 'Минимальная частота',
        error: 'Укажите минимальную частоту процессора'
      },
      cpuMaxFreq: {
        value: '',
        placeholder: '3200',
        legend: 'Укажите в MHz',
        label: 'Максимальная частота',
        error: 'Укажите максимальную частоту процессора'
      },
      cpuCores: {
        value: '',
        placeholder: '2',
        legend: 'Укажите кол-во ядер',
        label: 'Ядра/Потоки',
        error: 'Укажите кол-во ядер процессора'
      },
      os: {
        value: '',
        placeholder: 'win7',
        legend: 'Укажите ОС',
        label: 'Операционная система',
        endpoint: `${routes.search}os/`,
        error: 'Укажите операционную систему'
      },
      akb: {
        value: '',
        placeholder: '100',
        legend: 'Укажите значение от 0 до 100',
        label: 'АКБ',
        error: 'Укажите значение АКБ'
      },

      ramMemory: {
        value: '',
        placeholder: '8',
        legend: 'Укажите в Gb',
        label: 'Объем памяти',
        error: 'Укажите объем оперативной памяти'
      },
      ramFreq: {
        value: '',
        placeholder: '2400',
        legend: 'Укажите в MHz',
        label: 'Частота'
      },
      ramVendor: {
        value: '',
        placeholder: 'Intel',
        legend: 'Укажите производителя',
        label: 'Производитель',
        endpoint: `${routes.search}ram-vendor/`,
        error: 'Укажите производителя оперативной памяти'
      },
      ramType: {
        value: '',
        placeholder: 'DDR4',
        legend: 'Выберите значение из списка',
        label: 'Тип',
        endpoint: `${routes.search}ram-type/`,
        error: 'Укажите тип оперативной памяти'
      },
      gpuMemory: {
        value: '',
        placeholder: '8',
        legend: 'Укажите в Gb',
        label: 'Объем памяти',
        error: 'Укажите объем видеокарты'
      },
      gpuFreq: {
        value: '',
        placeholder: '2400',
        legend: 'Укажите в MHz',
        label: 'Частота'
      },
      gpuModel: {
        value: '',
        placeholder: 'AMD FirePro W4170M',
        legend: 'Выберите значение из списка',
        label: 'Модель',
        endpoint: `${routes.search}gpu-model/`,
        error: 'Укажите модель видеокарты'
      },
      screenDiagonal: {
        value: '',
        placeholder: '15.6',
        legend: 'Укажите диагональ в дюймах',
        label: 'Диагональ',
        endpoint: `${routes.search}screen-diagonal/`,
        error: 'Укажите диагональ матрицы'
      },
      screenResolution: {
        value: '',
        placeholder: '1280х1024',
        legend: 'Укажите разрешение',
        label: 'Разрешение',
        endpoint: `${routes.search}screen-resolution/`,
        error: 'Укажите разрешение матрицы'
      },
      screenType: {
        value: '',
        placeholder: 'Матовый',
        legend: 'Выберите значение из списка',
        label: 'Покрытие',
        endpoint: `${routes.search}screen-type/`,
        error: 'Укажите покрытие матрицы'
      },
      screenMatrix: {
        value: '',
        placeholder: 'TN',
        legend: 'Укажите тип матрицы',
        label: 'Матрица',
        endpoint: `${routes.search}screen-matrix/`,
        error: 'Укажите тип матрицы'
      },
      hards: [
        [
          {
            label: 'Модель',
            value: '',
            placeholder: 'Seagate',
            legend: 'Модель диска'
          },
          {
            label: 'Тип',
            value: '',
            placeholder: 'SSD',
            legend: 'Тип диска',
            options: ['SSD', 'HDD']
          },
          {
            label: 'Объем памяти',
            value: '',
            placeholder: '250',
            legend: 'Объем в Gb'
          },
          {
            label: 'Обороты',
            value: '',
            placeholder: '45000',
            legend: 'Кол-во оборотов'
          },
          {
            label: 'SMART',
            value: '',
            placeholder: '100',
            legend: 'От 0 до 100'
          },
        ]
      ]
    }
  }
}
</script>
