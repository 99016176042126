<template>
  <span :class="['c-badge', `c-badge--${color}`]">{{ label }}</span>
</template>

<script>
export default {
  name: "Badge",
  props: {
    label: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: 'secondary',
      validator: (value) => {
        return [
          'primary', 
          'secondary',
          'info',
          'success',
          'warning',
          'danger'
        ].indexOf(value) !== -1;
      }
    }
  }
}
</script>